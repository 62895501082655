import { ReactElement } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { 
    AdminPanelSettings, 
    Dashboard, 
    Category, 
    Star, 
    GroupWork, 
    Image, 
    CatchingPokemon,
    Login 
} from '@mui/icons-material';

// Icon map for admin routes and other built-in icons
export const iconMap: Record<string, ReactElement> = {
    'AdminPanelSettings': <AdminPanelSettings />,
    'Dashboard': <Dashboard />,
    'Category': <Category />,
    'Star': <Star />,
    'GroupWork': <GroupWork />,
    'Image': <Image />,
    'CatchingPokemon': <CatchingPokemon />,
    'Login': <Login />
};

export const processSidebarIcon = (iconData: string | undefined): React.ReactElement | undefined => {
    if (!iconData) {
        return undefined;
    }

    // Check if it's a URL
    if (typeof iconData === 'string' && (iconData.startsWith('http') || iconData.startsWith('/'))) {
        return (
            <LazyLoadImage
                src={iconData}
                alt="icon"
                effect="blur"
                width={24}
                height={24}
                style={{
                    objectFit: 'contain'
                }}
            />
        );
    }

    // Handle built-in MUI icons
    return iconMap[iconData] || undefined;
}; 