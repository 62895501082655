import React from 'react';
import { Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// Define the SetInfo type
interface SetInfo {
    setPrintRuns?: {
        firstEditionSet?: { buttonDisplay: string };
        shadowlessSet?: { buttonDisplay: string };
        unlimitedEditionSet?: { buttonDisplay: string };
        fourthPrintSet?: { buttonDisplay: string };
    };
    showAllCards?: boolean;
}

export interface ToggleButtonsProps {
    setInfo: SetInfo;
    select: string;
    handleToggle: (event: React.MouseEvent<HTMLElement>, newAlignment: string) => void;
    showFirstEdition: boolean;
    showShadowlessSet: boolean;
    showfourthPrintSet: boolean;
    showReverseSet: boolean;
    showSubSet: boolean;
    type?: string;
}

interface ColoredToggleButtonProps {
    customcolor: string;
}

const ColoredToggleButton = styled(ToggleButton)<ColoredToggleButtonProps>(
    ({ theme, customcolor }) => ({
        color: customcolor,
        '&.Mui-selected': {
            color: theme.palette.getContrastText(customcolor),
            backgroundColor: customcolor,
            '&:hover': {
                backgroundColor: customcolor,
                opacity: 0.9,
            },
        },
    })
);

const ToggleButtons: React.FC<ToggleButtonsProps> = ({
    setInfo,
    select,
    handleToggle,
    showFirstEdition,
    showShadowlessSet,
    showfourthPrintSet,
    showReverseSet,
    showSubSet,
    type,
}) => {
    // Add logging for setInfo
    // React.useEffect(() => {
    //     console.log('ToggleButtons: setInfo details:', {
    //         fullSetInfo: setInfo,
    //         setPrintRuns: setInfo.setPrintRuns,
    //         printRunDetails: {
    //             firstEdition: setInfo.setPrintRuns?.firstEditionSet,
    //             shadowless: setInfo.setPrintRuns?.shadowlessSet,
    //             unlimited: setInfo.setPrintRuns?.unlimitedEditionSet,
    //             fourthPrint: setInfo.setPrintRuns?.fourthPrintSet
    //         },
    //         showAllCards: setInfo.showAllCards
    //     });
    // }, [setInfo]);

    // Add wrapped handler for logging
    const handleToggleWithLogging = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        // console.log(`ToggleButtons: Button clicked - New value: ${newAlignment}, Previous value: ${select}`);
        handleToggle(event, newAlignment);
    };

    // Log when component renders with new props
    // React.useEffect(() => {
    //     console.log('ToggleButtons: Rendered with props:', {
    //         currentSelection: select,
    //         showFirstEdition,
    //         showShadowlessSet,
    //         showfourthPrintSet,
    //         showReverseSet,
    //         showSubSet,
    //         type,
    //         availableButtons: {
    //             firstEdition: setInfo.setPrintRuns?.firstEditionSet?.buttonDisplay,
    //             shadowless: setInfo.setPrintRuns?.shadowlessSet?.buttonDisplay,
    //             unlimited: setInfo.setPrintRuns?.unlimitedEditionSet?.buttonDisplay,
    //             fourthPrint: setInfo.setPrintRuns?.fourthPrintSet?.buttonDisplay
    //         }
    //     });
    // }, [select, showFirstEdition, showShadowlessSet, showfourthPrintSet, showReverseSet, showSubSet, type, setInfo]);

    return (
        <Paper elevation={4} style={{ padding: '16px', width: '100%' }}>
            <ToggleButtonGroup
                value={select}
                exclusive
                onChange={handleToggleWithLogging}
                aria-label="text alignment"
                style={{ width: '100%', display: 'flex', overflowX: 'auto' }}
            >
                {showFirstEdition && (
                    <ColoredToggleButton
                        style={{ flex: '1 0 25%' }}
                        value="First Edition"
                        customcolor="#0288d1"
                    >
                        {setInfo.setPrintRuns?.firstEditionSet?.buttonDisplay}
                    </ColoredToggleButton>
                )}
                {showShadowlessSet && (
                    <ColoredToggleButton
                        style={{ flex: '1 0 25%' }}
                        value="Shadowless"
                        customcolor="#9c27b0"
                    >
                        {setInfo.setPrintRuns?.shadowlessSet?.buttonDisplay}
                    </ColoredToggleButton>
                )}
                <ColoredToggleButton
                    style={{ flex: '1 0 25%' }}
                    value="Unlimited"
                    customcolor="#ed6c02"
                >
                    {setInfo.setPrintRuns?.unlimitedEditionSet?.buttonDisplay}
                </ColoredToggleButton>
                {showfourthPrintSet && (
                    <ColoredToggleButton
                        style={{ flex: '1 0 25%' }}
                        value="4th Print"
                        customcolor="#d32f2f"
                    >
                        {setInfo.setPrintRuns?.fourthPrintSet?.buttonDisplay}
                    </ColoredToggleButton>
                )}
                {showReverseSet && (
                    <ColoredToggleButton
                        style={{ flex: '1 0 25%' }}
                        value="Reverse Set"
                        customcolor="#7b1fa2"
                    >
                        Reverse Set
                    </ColoredToggleButton>
                )}
                {showSubSet && (
                    <ColoredToggleButton
                        style={{ flex: '1 0 25%' }}
                        value="Sub-Set"
                        customcolor="#0288d1"
                    >
                        Sub Set
                    </ColoredToggleButton>
                )}
                {(type !== 'WOTC' || setInfo.showAllCards === true) && (
                    <ColoredToggleButton
                        style={{ flex: '1 0 25%' }}
                        value="All Cards"
                        customcolor="#2e7d32"
                    >
                        All Cards
                    </ColoredToggleButton>
                )}
            </ToggleButtonGroup>
        </Paper>
    );
};

export default ToggleButtons;
