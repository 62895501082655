import React from 'react';
import { Paper, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Grid, Skeleton, LinearProgress, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { SeriesSummary, SetSummary } from '../../types/collection';
import EditionProgress from './EditionProgress';

interface CollectionSummaryPanelProps {
    userName: string;
    loading: boolean;
    summary: SeriesSummary[] | null;
    error: string | null;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '20px',
    height: '100%',
}));

const StyledAccordion = styled(Accordion)({
    '&:before': {
        display: 'none',
    },
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '& .MuiAccordionDetails-root': {
        padding: '16px',
    },
    '& .MuiAccordionSummary-root': {
        padding: '0 16px',
    }
});

const SetCard = styled(Box)<{ logourl: string }>(({ theme, logourl }) => ({
    padding: '8px 12px',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginBottom: '8px',
    display: 'flex',
    gap: '12px',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        inset: '-50%',
        backgroundImage: logourl ? `url("${logourl}")` : 'none',
        backgroundPosition: 'center',
        backgroundSize: '200%',
        opacity: 0.25,
        filter: 'blur(15px)',
        zIndex: 0,
        pointerEvents: 'none'
    },
    '& > *': {
        position: 'relative',
        zIndex: 1
    }
}));

const SetLogo = styled('img')({
    width: '24px',
    height: '24px',
    objectFit: 'contain',
    alignSelf: 'center'
});

const ProgressContainer = styled(Box)({
    marginTop: '6px',
    flex: 1,
});

const SeriesContainer = styled(Box)(({ theme }) => ({
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    marginBottom: '16px',
    '&:last-child': {
        marginBottom: 0
    }
}));

// Create a styled div to handle the logo
const LogoContainer = styled('div')<{ logourl?: string }>`
  background-image: ${props => props.logourl ? `url(${props.logourl})` : 'none'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const CollectionSummaryPanel: React.FC<CollectionSummaryPanelProps> = ({
    userName,
    loading,
    summary,
    error
}) => {
    const getEditionLabel = (editionName: string, setType: string) => {
        if (editionName === 'unlimited') {
            return setType === 'WOTC' ? 'Unlimited' : 'Main Set';
        }
        return editionName.split(/(?=[A-Z])/).join(' ');
    };

    const renderSetProgress = (set: SetSummary) => (
        <SetCard key={set.setId} logourl={set.setLogoBackground}>
            <SetLogo 
                src={set.setLogo} 
                alt={set.setName}
                onError={(e) => {
                    const img = e.target as HTMLImageElement;
                    img.style.display = 'none';
                }}
            />
            <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="subtitle1">{set.setName}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        {set.collectedCards} / {set.totalCards} ({set.percentage}%)
                    </Typography>
                </Box>
                <ProgressContainer>
                    {set.editions.firstEdition && (
                        <EditionProgress 
                            label="First Edition"
                            data={set.editions.firstEdition}
                            color="#0288d1"
                        />
                    )}
                    {set.editions.shadowless && (
                        <EditionProgress 
                            label="Shadowless"
                            data={set.editions.shadowless}
                            color="#9c27b0"
                        />
                    )}
                    {set.editions.unlimited && (
                        <EditionProgress 
                            label={getEditionLabel('unlimited', set.setType)}
                            data={set.editions.unlimited}
                            color="#ed6c02"
                        />
                    )}
                    {set.editions.fourthPrint && (
                        <EditionProgress 
                            label="4th Print"
                            data={set.editions.fourthPrint}
                            color="#d32f2f"
                        />
                    )}
                    {set.editions.reverseHolo && (
                        <EditionProgress 
                            label="Reverse Holo"
                            data={set.editions.reverseHolo}
                            color="#7b1fa2"
                        />
                    )}
                    {set.editions.subset && (
                        <EditionProgress 
                            label="Subset"
                            data={set.editions.subset}
                            color="#0288d1"
                        />
                    )}
                </ProgressContainer>
            </Box>
        </SetCard>
    );

    if (error) {
        return (
            <StyledPaper>
                <Typography color="error">{error}</Typography>
            </StyledPaper>
        );
    }

    if (loading) {
        return (
            <StyledPaper>
                <Typography variant="h6" sx={{ mb: 3 }}>Collection Summary</Typography>
                {[1, 2, 3].map((i) => (
                    <Skeleton key={i} height={100} sx={{ mb: 2 }} />
                ))}
            </StyledPaper>
        );
    }

    if (!summary || summary.length === 0) {
        return (
            <StyledPaper>
                <Typography variant="h6" sx={{ mb: 3 }}>Collection Summary</Typography>
                <Typography>No collection data available.</Typography>
            </StyledPaper>
        );
    }

    return (
        <StyledPaper>
            <Typography variant="h6" sx={{ mb: 3 }}>Collection Summary</Typography>
            <Grid container spacing={2}>
                {summary?.map((series) => (
                    <Grid item xs={12} md={summary.length === 1 ? 12 : 6} key={series.seriesId}>
                        <SeriesContainer>
                            <StyledAccordion defaultExpanded>
                                <AccordionSummary 
                                    expandIcon={<ExpandMoreIcon />}
                                    sx={{
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                                        '&.Mui-expanded': {
                                            minHeight: '48px',
                                        }
                                    }}
                                >
                                    <Box sx={{ 
                                        width: '100%', 
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        alignItems: 'center'
                                    }}>
                                        <Typography variant="subtitle1">{series.seriesName}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {series.collectedCards} / {series.totalCards} ({series.percentage}%)
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} sx={{ mt: 0 }}>
                                        {series.sets.map((set) => (
                                            <Grid item xs={12} key={set.setId}>
                                                {renderSetProgress(set)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </StyledAccordion>
                        </SeriesContainer>
                    </Grid>
                ))}
            </Grid>
        </StyledPaper>
    );
};

export default CollectionSummaryPanel; 