import React from 'react';
import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';
import { EditionSummary } from '../../types/collection';

interface EditionProgressProps {
    label: string;
    data: EditionSummary;
    color: string;
}

const EditionProgress: React.FC<EditionProgressProps> = ({ label, data, color }) => {
    return (
        <Box sx={{ width: '100%', mb: 0.75 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.25 }}>
                <Typography variant="body2" color="text.secondary">
                    {label}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {data.collected} / {data.total} ({data.percentage}%)
                </Typography>
            </Box>
            <LinearProgress
                variant="determinate"
                value={data.percentage}
                sx={{
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: `${color}22`,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: color,
                        borderRadius: 3,
                    },
                }}
            />
        </Box>
    );
};

export default EditionProgress; 