import { Drawer, List, Stack, Toolbar } from '@mui/material';
import { useCallback, useMemo } from 'react';
import assets from '../../assets';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
import { RouteType } from '../../routes/config';
import Avatar from '@mui/material/Avatar';
import '../../css/sideBar.css';
import { useSidebarRoutes } from '../../routes';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import '../../css/footer.css';

const Sidebar = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const { authenticated, userName, favoritePokemonImage } = auth;
    const authenticatedStorage = localStorage.getItem('authenticated');
    const routes = useSidebarRoutes();
    const adminKey = localStorage.getItem('adminKey');

    const filterRoute = useCallback((route: RouteType): boolean => {
        if (route.beta) {
            return false;
        }

        if (route.path?.startsWith('/admin')) {
            return !!adminKey;
        }

        if (route.state === 'Login') {
            return !authenticated && !authenticatedStorage;
        }

        if (route.state === 'Logout') {
            return authenticated || authenticatedStorage === 'true';
        }

        return !!route.displayName;
    }, [authenticated, authenticatedStorage, adminKey]);

    const processRoute = useCallback((route: RouteType): RouteType => {
        if (route.child) {
            return {
                ...route,
                child: route.child
                    .filter(filterRoute)
                    .map(childRoute => processRoute(childRoute))
            };
        }
        return route;
    }, [filterRoute]);

    const filteredRoutes = useMemo(() => {
        return routes
            .filter(route => {
                const baseFilter = filterRoute(route);
                if (route.isMenu && route.child && route.child.length > 0) {
                    return true;
                }
                return baseFilter;
            })
            .map(route => processRoute(route));
    }, [routes, filterRoute, processRoute]);

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: sizeConfigs.sidebar.width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: sizeConfigs.sidebar.width,
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                },
            }}
        >
            <List disablePadding>
                <Toolbar>
                    <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
                        <Link to="/">
                            <img
                                style={{ width: 'auto', height: 'auto', maxWidth: '100px' }}
                                src={assets.site.images.logo}
                                alt=""
                            />
                        </Link>
                    </Stack>
                </Toolbar>

                <div className="sidebar-userProfile">
                    {authenticated ? (
                        <>
                            <Link to={`/u/${userName ?? 'Guest'}`}>
                                <Avatar
                                    alt={userName ?? 'Guest'}
                                    src={favoritePokemonImage || assets.pokedex.pokemon.PK123}
                                    key={favoritePokemonImage}
                                />
                            </Link>
                            <Link to={`/u/${userName ?? 'Guest'}`}>
                                <p>{userName ?? 'Guest'}</p>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Avatar alt="Guest" src={assets.pokedex.pokemon.PK123} />
                            <p>Guest</p>
                        </>
                    )}
                </div>

                {filteredRoutes.map((route, index) =>
                    route.displayName ? (
                        route.child ? (
                            <SidebarItemCollapse item={route} key={`${route.path}-${index}`} />
                        ) : (
                            <SidebarItem item={route} key={`${route.path}-${index}`} />
                        )
                    ) : null
                )}
            </List>
            <div className="footer-container">
                {/* <Link className="privacy-policy-link" to="/privacy-policy">
                    Privacy Policy
                </Link> */}
                <p className="footer-text">
                    MyPokellection is not affiliated with, sponsored or endorsed by, or in any way 
                    associated with Pokemon or The Pokemon Company International Inc
                </p>
            </div>
        </Drawer>
    );
};

export default Sidebar;
