import { toast } from 'react-toastify';
import config from '../routes/config';

export interface CardApiResponse {
    success: boolean;
    message?: string;
    [key: string]: any;
}

interface UserCaughtPokemonResponse extends CardApiResponse {
    caughtPokemon?: number;
}

const handleApiError = (error: any, defaultMessage: string): CardApiResponse => {
    return {
        success: false,
        message: error.message || defaultMessage,
    };
};

const fetchConfig = {
    credentials: 'include' as RequestCredentials,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

export const getFeaturedPokemonCardCount = async (): Promise<CardApiResponse> => {
    try {
        const response = await fetch(`${config.API_URL}/getFeaturedPokemonCardCount`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: CardApiResponse = await response.json();
        if (!result.success) {
            toast.error('Failed to fetch featured Pokemon card count');
        }
        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching featured Pokemon card count');
    }
};

export const getCameoPokemonCardCount = async (): Promise<CardApiResponse> => {
    try {
        const response = await fetch(`${config.API_URL}/getCameoPokemonCardCount`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: CardApiResponse = await response.json();
        if (!result.success) {
            toast.error('Failed to fetch cameo Pokemon card count');
        }
        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching cameo Pokemon card count');
    }
};

export const getUserCaughtPokemon = async (username: string): Promise<UserCaughtPokemonResponse> => {
    try {
        const response = await fetch(
            `${config.API_URL}/getUserCaughtPokemon?username=${encodeURIComponent(username)}`,
            {
                ...fetchConfig,
                method: 'GET'
            }
        );

        const result: UserCaughtPokemonResponse = await response.json();
        if (!result.success) {
            toast.error('Failed to fetch user caught Pokemon count');
        }
        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching user caught Pokemon count');
    }
};

export const getFeaturedCaughtPokemonCardCount = async (username: string): Promise<CardApiResponse> => {
    try {
        const response = await fetch(
            `${config.API_URL}/getFeaturedCaughtPokemonCardCount?username=${encodeURIComponent(username)}`,
            {
                ...fetchConfig,
                method: 'GET'
            }
        );

        const result: CardApiResponse = await response.json();
        if (!result.success) {
            toast.error('Failed to fetch featured caught Pokemon card count');
        }
        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching featured caught Pokemon card count');
    }
};

export const getCameoCaughtPokemonCardCount = async (
    username: string
): Promise<CardApiResponse> => {
    try {
        const response = await fetch(
            `${config.API_URL}/getCameoCaughtPokemonCardCount?username=${encodeURIComponent(username)}`,
            {
                ...fetchConfig,
                method: 'GET'
            }
        );

        const result: CardApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch cameo caught Pokemon card count');
        }

        return result;
    } catch (error) {
        return handleApiError(
            error,
            'An error occurred while fetching cameo caught Pokemon card count'
        );
    }
};

export const getUserPokedexValue = async (
    authParams: any,
    username: string
): Promise<CardApiResponse> => {
    if (!authParams) {
        return { success: false, message: 'Authentication parameters are missing' };
    }

    if (!username) {
        return { success: false, message: 'Username is missing' };
    }

    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const requestBody = {
            ...stringAuthParams,
            username: username,
        };

        const queryString = new URLSearchParams(requestBody).toString();

        const response = await fetch(`${config.API_URL}/getUserPokedexValue?${queryString}`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: CardApiResponse = await response.json();
        if (!result.success) {
            toast.error('Failed to fetch user Pokedex value');
        }
        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching user Pokedex value');
    }
};

export const getFavoritePokemonCardCount = async (
    authParams: any,
    username: string,
    favoritePokemonId: number
): Promise<CardApiResponse> => {
    try {
        const queryParams = new URLSearchParams({
            ...authParams,
            username,
            favoritePokemonId: favoritePokemonId.toString(),
        }).toString();
        const response = await fetch(`${config.API_URL}/getFavoritePokemonCardCount?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const rawText = await response.text();
        try {
            return JSON.parse(rawText);
        } catch (parseError) {
            return { success: false, message: 'Invalid JSON response from server' };
        }
    } catch (error) {
        return handleApiError(
            error,
            'An error occurred while fetching favorite Pokémon card count'
        );
    }
};

export const getFavoritePokemonFeaturedCardCount = async (
    authParams: any,
    username: string,
    favoritePokemonId: number
): Promise<CardApiResponse> => {
    try {
        const queryParams = new URLSearchParams({
            ...authParams,
            username,
            favoritePokemonId: favoritePokemonId.toString(),
        }).toString();
        const response = await fetch(`${config.API_URL}/getFavoritePokemonFeaturedCardCount?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        return await response.json();
    } catch (error) {
        return handleApiError(
            error,
            'An error occurred while fetching favorite Pokémon featured card count'
        );
    }
};

export const getFavoritePokemonCameoCardCount = async (
    authParams: any,
    username: string,
    favoritePokemonId: number
): Promise<CardApiResponse> => {
    try {
        const queryParams = new URLSearchParams({
            ...authParams,
            username,
            favoritePokemonId: favoritePokemonId.toString(),
        }).toString();
        const response = await fetch(`${config.API_URL}/getFavoritePokemonCameoCardCount?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return {
            success: false,
            message: 'An error occurred while fetching favorite Pokémon cameo card count',
        };
    }
};

export const getCollectedFavoritePokemonCardCount = async (
    authParams: any,
    username: string,
    favoritePokemonId: number
): Promise<CardApiResponse> => {
    try {
        const queryParams = new URLSearchParams({
            ...authParams,
            username,
            favoritePokemonId: favoritePokemonId.toString(),
        }).toString();
        const response = await fetch(`${config.API_URL}/getCollectedFavoritePokemonCardCount?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        return await response.json();
    } catch (error) {
        return handleApiError(
            error,
            'An error occurred while fetching collected favorite Pokémon card count'
        );
    }
};

export const getCollectedFavoritePokemonFeaturedCardCount = async (
    authParams: any,
    username: string,
    favoritePokemonId: number
): Promise<CardApiResponse> => {
    try {
        const queryParams = new URLSearchParams({
            ...authParams,
            username,
            favoritePokemonId: favoritePokemonId.toString(),
        }).toString();
        const response = await fetch(
            `${config.API_URL}/getCollectedFavoritePokemonFeaturedCardCount?${queryParams}`,
            {
                ...fetchConfig,
                method: 'GET'
            }
        );
        return await response.json();
    } catch (error) {
        return handleApiError(
            error,
            'An error occurred while fetching collected favorite Pokémon featured card count'
        );
    }
};

export const getCollectedFavoritePokemonCameoCardCount = async (
    authParams: any,
    username: string,
    favoritePokemonId: number
): Promise<CardApiResponse> => {
    try {
        const queryParams = new URLSearchParams({
            ...authParams,
            username,
            favoritePokemonId: favoritePokemonId.toString(),
        }).toString();
        const response = await fetch(
            `${config.API_URL}/getCollectedFavoritePokemonCameoCardCount?${queryParams}`,
            {
                ...fetchConfig,
                method: 'GET'
            }
        );
        return await response.json();
    } catch (error) {
        return handleApiError(
            error,
            'An error occurred while fetching collected favorite Pokémon cameo card count'
        );
    }
};

export const getFeaturedCards = async (pokemonId: number) => {
    try {
        const queryParams = new URLSearchParams({
            pokemonId: pokemonId.toString(),
        }).toString();

        const response = await fetch(`${config.API_URL}/getFeaturedCards?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });

        return await response.json();
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching featured cards');
    }
};

export const getCameoCards = async (pokemonId: number) => {
    try {
        const queryParams = new URLSearchParams({
            pokemonId: pokemonId.toString(),
        }).toString();

        const response = await fetch(`${config.API_URL}/getCameoCards?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });

        return await response.json();
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching cameo cards');
    }
};

export const getFeaturedCaughtPokemonCardIds = async (
    username: string,
    authParams: any,
    pokemonId: number
) => {
    try {
        const queryParams = new URLSearchParams({
            ...authParams,
            username,
            pokemonId: pokemonId.toString(),
        }).toString();
        const response = await fetch(`${config.API_URL}/getFeaturedCaughtPokemonCardIds?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        return await response.json();
    } catch (error) {
        return handleApiError(
            error,
            'An error occurred while fetching featured caught Pokemon card IDs'
        );
    }
};

export const getCameoCaughtPokemonCardIds = async (
    username: string,
    authParams: any,
    pokemonId: number
) => {
    try {
        const queryParams = new URLSearchParams({
            ...authParams,
            username,
            pokemonId: pokemonId.toString(),
        }).toString();
        const response = await fetch(`${config.API_URL}/getCameoCaughtPokemonCardIds?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        return await response.json();
    } catch (error) {
        return handleApiError(
            error,
            'An error occurred while fetching cameo caught Pokemon card IDs'
        );
    }
};
