import React, { useState, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { PKRoute } from './RouteManager';
import RouteEditDialog from './RouteEditDialog';

interface RouteManagerTableProps {
    routes: PKRoute[];
    onRouteUpdate: (route: PKRoute) => Promise<void>;
}

const RouteManagerTable: React.FC<RouteManagerTableProps> = ({ routes, onRouteUpdate }) => {
    console.log('RouteManagerTable render');
    const [editingRoute, setEditingRoute] = useState<PKRoute | null>(null);
    const [editedValues, setEditedValues] = useState<Partial<PKRoute>>({});
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleEdit = useCallback((route: PKRoute) => {
        console.log('handleEdit called with route:', route);
        setEditingRoute(route);
        setEditedValues({
            ...route,
            parent_route_id: route.parent_route_id || null
        });
        setDialogOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        console.log('handleClose called');
        setDialogOpen(false);
        setEditingRoute(null);
        setEditedValues({});
    }, []);

    const handleSave = useCallback(async () => {
        if (editingRoute && editedValues) {
            const updatedRoute = { ...editingRoute, ...editedValues };
            await onRouteUpdate(updatedRoute);
            handleClose();
        }
    }, [editingRoute, editedValues, onRouteUpdate, handleClose]);

    const handleChange = useCallback((field: keyof PKRoute, value: any) => {
        console.log('handleChange called', { field, value });
        setEditedValues(prev => ({
            ...prev,
            [field]: value
        }));
    }, []);

    return (
        <>
            <TableContainer component={Paper} className="route-manager-table">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Parent ID</TableCell>
                            <TableCell>Path</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Display Name</TableCell>
                            <TableCell>State</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(routes) && routes.map((route) => (
                            <TableRow key={route.route_id}>
                                <TableCell>
                                    <Tooltip title="Edit">
                                        <IconButton onClick={() => handleEdit(route)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{route.route_id}</TableCell>
                                <TableCell>{route.parent_route_id || 'None'}</TableCell>
                                <TableCell>{route.path}</TableCell>
                                <TableCell>{route.route_name}</TableCell>
                                <TableCell>{route.display_name}</TableCell>
                                <TableCell>{route.state}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <RouteEditDialog 
                open={dialogOpen}
                onClose={handleClose}
                onSave={handleSave}
                values={editedValues}
                onChange={handleChange}
            />
        </>
    );
};

export default React.memo(RouteManagerTable); 