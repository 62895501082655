import { toast } from 'react-toastify';
import config from '../../routes/config';

const fetchConfig = {
    credentials: 'include' as RequestCredentials,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

export function handleAPIResult(result: { success: any; failed: any; message: String }) {
    if (result.success) {
        toast.success(`${result.message}`, {
            autoClose: 1000,
        });
    } else if (result.failed) {
        toast.error(`${result.message}`, {
            autoClose: 1000,
        });
    }
}

export const addCardToCollection = (cardId: any, authParams: string) => {
    const authParamsObj = Object.fromEntries(new URLSearchParams(authParams));

    const body = {
        cardId,
        ...authParamsObj,
    };

    const options = {
        method: 'POST',
        ...fetchConfig,
        body: JSON.stringify(body),
    };

    fetch(`${config.API_URL}/addCardToCollection?${authParams}`, options)
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((result) => {
            handleAPIResult({
                success: true,
                failed: false,
                message: 'Success: Added card to collection!',
            });
        })
        .catch((error) => {
            handleAPIResult({
                success: false,
                failed: true,
                message: 'Failed: Unable to add card to collection',
            });
        });
};

export const removeCardFromCollection = (cardId: any, authParams: string) => {
    const authParamsObj = Object.fromEntries(new URLSearchParams(authParams));

    const body = {
        cardId,
        ...authParamsObj,
    };

    const options = {
        method: 'DELETE',
        ...fetchConfig,
        body: JSON.stringify(body),
    };

    fetch(`${config.API_URL}/removeCardFromCollection?${authParams}`, options)
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((result) =>
            handleAPIResult({
                success: true,
                failed: false,
                message: 'Success: Removed card from collection!',
            })
        )
        .catch((error) =>
            handleAPIResult({
                success: false,
                failed: true,
                message: 'Failed: Unable to remove card from collection',
            })
        );
};
