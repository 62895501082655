import { useEffect, useState, createContext, useContext, useMemo, useRef } from 'react';
import { Route } from 'react-router-dom';
import PageWrapper from '../components/layout/PageWrapper';
import { publicRoutes, authRoutes, hiddenRoutes, privacyPolicyRoute } from './appRoutes';
import { RouteType } from './config';
import { useUserFunctions } from '../hooks/useUserFunctions';
import { useAuth } from '../context/AuthContext';
import { getApplicationRoutes } from '../api/adminApi';
import { getComponent } from '../utils/componentRegistry';

// Helper function to convert API route data to RouteType
const processApiRoute = (route: any, isUserAdmin: boolean): RouteType | null => {
    // console.log('Processing route:', {
    //     path: route.path,
    //     isUserAdmin,
    //     isAdminRoute: route.path?.startsWith('/admin') || route.path?.includes('admin') || route.state === 'admin',
    //     isBeta: route.is_beta
    // });

    // Skip beta routes and admin routes for non-admin users
    if (route.is_beta) {
        // console.log('Skipping beta route:', route.path);
        return null;
    }

    if (!isUserAdmin && route.state === 'admin') {
        // console.log('Skipping admin route:', route.path);
        return null;
    }

    const Component = route.component_name ? getComponent(route.component_name) : null;
    const isSetRoute = route.component_name === 'TemplateSet' && route.pokemon_set;
    const isComingSoonRoute = route.component_name === 'ComingSoonTemplate';
    
    // Process child routes first if they exist
    let processedChildren: RouteType[] | undefined = undefined;
    if (route.child && route.child.length > 0) {
        // Process and filter out null children
        const validChildren = route.child
            .map((childRoute: any) => processApiRoute(childRoute, isUserAdmin))
            .filter((child: RouteType | null): child is RouteType => child !== null);
            
        processedChildren = validChildren.length > 0 ? validChildren : undefined;
    }

    if (isComingSoonRoute) {
        const transformedSetInfo = {
            logo: route.pokemon_set?.logo_location || '',
            setName: route.pokemon_set?.name || '',
            setSeries: route.pokemon_set?.seriesName || '',
            releaseDate: route.pokemon_set?.releaseDate || '',
            description: route.pokemon_set?.description || '',
            tcgplayerlink: route.pokemon_set?.tcgplayerlink || '',
            summary: route.pokemon_set?.summary || '',
            knownCards: route.pokemon_set?.knownCards || []
        };

        return {
            path: route.is_index ? undefined : route.path,
            element: Component ? <Component setInfo={transformedSetInfo} /> : <div>Component not found</div>,
            state: route.state,
            tcgState: route.tcg_state || 'All',
            beta: Boolean(route.is_beta),
            isHidden: Boolean(route.is_hidden),
            index: Boolean(route.is_index),
            isSet: false,
            isMenu: false,
            displayName: route.displayName,
            icon: route.icon,
            pokemon_set: route.pokemon_set,
            setInfo: transformedSetInfo,
            child: processedChildren
        };
    }

    if (isSetRoute) {
        // Helper function to safely parse numbers
        const parseNumber = (value: string | number | undefined): number => {
            
            if (typeof value === 'undefined' || value === null) {
                return 0;
            }

            if (typeof value === 'number') {
                return value;
            }

            const parsed = parseInt(value, 10);
            const result = isNaN(parsed) ? 0 : parsed;
            return result;
        };

        const transformedSetInfo = {
            type: route.pokemon_set.type || 'PokemonCompany',
            setName: route.pokemon_set.name,
            setSeries: route.pokemon_set.seriesName,
            setCardCounts: {
                numberOfCards: parseNumber(route.pokemon_set.setCardCounts?.numberOfCards),
                setNumber: parseNumber(route.pokemon_set.setCardCounts?.setNumber),
                numOfReverseSet: parseNumber(route.pokemon_set.setCardCounts?.numOfReverseSet),
                numberOfSubSet: parseNumber(route.pokemon_set.setCardCounts?.numberOfSubSet),
                mainSetWithSecretRares: parseNumber(route.pokemon_set.setCardCounts?.mainSetWithSecretRares),
                pr_first_edition_count: parseNumber(route.pokemon_set.setCardCounts?.pr_first_edition_count),
                pr_shadowless_count: parseNumber(route.pokemon_set.setCardCounts?.pr_shadowless_count),
                pr_unlimited_count: parseNumber(route.pokemon_set.setCardCounts?.pr_unlimited_count),
                pr_fourth_print_count: parseNumber(route.pokemon_set.setCardCounts?.pr_fourth_print_count)
            },
            showPriceData: Boolean(route.show_price_data),
            releaseDate: route.pokemon_set.releaseDate || '',
            showAllCards: route.pokemon_set.type === 'WOTC' ? false : true,
            logo: route.pokemon_set.logo_location || '',
            icon: route.pokemon_set.icon_location || '',
            setPrintRuns: route.pokemon_set.setPrintRuns,
            includesReverseHolographicCards: Boolean(route.pokemon_set.includesReverseHolographicCards),
            tcgplayerlink: route.pokemon_set.tcgplayerlink
        };

        return {
            path: route.is_index ? undefined : route.path,
            element: Component ? <Component setInfo={transformedSetInfo} /> : <div>Component not found</div>,
            state: route.state,
            tcgState: route.tcg_state || 'All',
            beta: Boolean(route.is_beta),
            isHidden: Boolean(route.is_hidden),
            index: Boolean(route.is_index),
            isSet: true,
            isMenu: false,
            displayName: route.displayName,
            icon: route.icon,
            pokemon_set: route.pokemon_set,
            setInfo: transformedSetInfo,
            child: processedChildren
        };
    }

    // Handle non-set routes
    const processedRoute: RouteType = {
        path: route.is_index ? undefined : route.path,
        element: Component ? <Component /> : <div>Component "{route.component_name}" not found</div>,
        state: route.state,
        tcgState: route.tcg_state || 'All',
        beta: Boolean(route.is_beta),
        isHidden: Boolean(route.is_hidden),
        index: Boolean(route.is_index),
        isSet: false,
        isMenu: Boolean(route.is_menu),
        displayName: route.displayName,
        icon: route.icon,
        pokemon_set: undefined,
        setInfo: undefined,
        child: processedChildren
    };

    return processedRoute;
};

const generateRoute = (routes: RouteType[]): React.ReactElement[] => {
    return routes.map((route, index) => {
        const routeKey = route.path || `index-${index}`;
        
        if (route.index) {
            return (
                <Route
                    index
                    element={<PageWrapper state={route.state}>{route.element}</PageWrapper>}
                    key={routeKey}
                />
            );
        }
        
        return (
            <Route
                path={route.path}
                element={
                    <PageWrapper state={route.child ? undefined : route.state}>
                        {route.element}
                    </PageWrapper>
                }
                key={routeKey}
            >
                {route.child && generateRoute(route.child)}
            </Route>
        );
    });
};

// First, create a context to share the routes and loading state
interface RoutesContextType {
    routes: RouteType[];
    routeElements: React.ReactElement[];
    isLoading: boolean;
    hasAccess: boolean;
}

const RoutesContext = createContext<RoutesContextType | null>(null);

// Create a provider component
export const RoutesProvider = ({ children }: { children: React.ReactNode }) => {
    const [apiRoutes, setApiRoutes] = useState<RouteType[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    const { isAdmin } = useUserFunctions();
    const { authParams, isAuthenticated } = useAuth();

    // Use ref to track the fetch status
    const fetchRef = useRef({
        isLoading: false,
        lastAuthState: '',
    });

    useEffect(() => {
        const fetchRoutes = async () => {
            // console.log('Fetching routes...');

            // Create a state string to compare against
            const currentAuthState = `${isAuthenticated}-${!!authParams}-${isAdmin}`;
            
            // Add debug logging
            // console.log('Authentication Status:', {
            //     isAuthenticated,
            //     hasAuthParams: !!authParams,
            //     isAdmin,
            //     currentAuthState
            // });
            
            // Skip if we're already loading or if nothing has changed
            if (fetchRef.current.isLoading || 
                fetchRef.current.lastAuthState === currentAuthState) {
                return;
            }

            fetchRef.current.isLoading = true;
            fetchRef.current.lastAuthState = currentAuthState;
            
            setIsLoading(true);
            
            // console.log('Before Try...');
            try {
                let result;
                if (isAuthenticated && authParams) {
                    result = await getApplicationRoutes(authParams);
                } else {
                    result = await getApplicationRoutes({});
                }

                if (result.success && result.routes) {
                    const processedApiRoutes = result.routes
                        .map(route => processApiRoute(route, isAdmin))
                        .filter((route): route is RouteType => route !== null);

                    // Filter auth routes based on authentication state
                    const filteredAuthRoutes = authRoutes.filter((route: RouteType) => {
                        if (isAuthenticated) {
                            return !route.hideWhenAuth;
                        }
                        return !route.requiresAuth;
                    });

                    // Combine all routes
                    const combinedRoutes = [
                        ...publicRoutes,
                        ...filteredAuthRoutes,
                        ...hiddenRoutes,
                        ...(isAdmin ? processedApiRoutes : processedApiRoutes.filter(route => 
                            !route.path?.includes('admin') && 
                            route.state !== 'admin'
                        )),
                        privacyPolicyRoute
                    ];

                    setApiRoutes(combinedRoutes);
                    setHasAccess(isAdmin);
                }
            } catch (error) {
                console.error('Error fetching routes:', error);
                // On error, fall back to basic routes
                const filteredAuthRoutes = authRoutes.filter((route: RouteType) => {
                    if (isAuthenticated) {
                        return !route.hideWhenAuth;
                    }
                    return !route.requiresAuth;
                });
                
                setApiRoutes([...publicRoutes, ...filteredAuthRoutes, ...hiddenRoutes]);
                setHasAccess(false);
            } finally {
                setIsLoading(false);
                fetchRef.current.isLoading = false;
            }
        };

        fetchRoutes();
    }, [isAuthenticated, authParams, isAdmin]);

    const value = useMemo(() => ({
        routes: apiRoutes,
        routeElements: generateRoute(apiRoutes),
        isLoading,
        hasAccess
    }), [apiRoutes, isLoading, hasAccess]);

    return (
        <RoutesContext.Provider value={value}>
            {children}
        </RoutesContext.Provider>
    );
};

// Replace the existing hooks with these new ones that use the context
export const useRoutes = () => {
    const context = useContext(RoutesContext);
    if (!context) throw new Error('useRoutes must be used within a RoutesProvider');
    return {
        routeElements: context.routeElements,
        routes: context.routes
    };
};

export const useSidebarRoutes = () => {
    const context = useContext(RoutesContext);
    if (!context) throw new Error('useSidebarRoutes must be used within a RoutesProvider');
    
    // Memoize the filtered routes to prevent unnecessary re-renders
    const filteredRoutes = useMemo(() => 
        context.routes.filter(route => !route.isHidden),
        [context.routes]
    );
    
    return filteredRoutes;
};

export { RoutesContext };
