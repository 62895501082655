import React from 'react';
import { Paper, Typography, Box, TextField } from '@mui/material';

interface RouteManagerHeaderProps {
    totalRoutes: number;
    searchTerm: string;
    onSearchChange: (term: string) => void;
}

const RouteManagerHeader: React.FC<RouteManagerHeaderProps> = ({ 
    totalRoutes, 
    searchTerm, 
    onSearchChange 
}) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h5">Route Manager</Typography>
                <Typography variant="subtitle1">
                    Total Routes: {totalRoutes}
                </Typography>
            </Box>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Search routes..."
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
                size="small"
            />
        </Paper>
    );
};

export default RouteManagerHeader; 