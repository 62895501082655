import React, { useState, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { PokemonSet } from '../../../../types/admin';
import PokemonSetEditDialog from './PokemonSetEditDialog';

interface PokemonSetManagerTableProps {
    sets: PokemonSet[];
    onSetUpdate: (set: PokemonSet) => Promise<void>;
}

const PokemonSetManagerTable: React.FC<PokemonSetManagerTableProps> = ({ sets, onSetUpdate }) => {
    const [editingSet, setEditingSet] = useState<PokemonSet | null>(null);
    const [editedValues, setEditedValues] = useState<Partial<PokemonSet>>({});
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleEdit = useCallback((set: PokemonSet) => {
        setEditingSet(set);
        setEditedValues(set);
        setDialogOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setDialogOpen(false);
        setEditingSet(null);
        setEditedValues({});
    }, []);

    const handleSave = useCallback(async () => {
        if (editingSet && editedValues) {
            const updatedSet = { ...editingSet, ...editedValues };
            await onSetUpdate(updatedSet);
            handleClose();
        }
    }, [editingSet, editedValues, onSetUpdate, handleClose]);

    const handleChange = useCallback((field: keyof PokemonSet, value: any) => {
        setEditedValues(prev => ({
            ...prev,
            [field]: value
        }));
    }, []);

    return (
        <>
            <TableContainer component={Paper} className="pokemon-set-manager-table">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Series ID</TableCell>
                            <TableCell>Cards</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Release Date</TableCell>
                            <TableCell>Set Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(sets) && sets.map((set) => (
                            <TableRow key={set.id}>
                                <TableCell>
                                    <Tooltip title="Edit">
                                        <IconButton onClick={() => handleEdit(set)}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{set.id}</TableCell>
                                <TableCell>{set.name}</TableCell>
                                <TableCell>{set.seriesId}</TableCell>
                                <TableCell>{set.numberOfCards}</TableCell>
                                <TableCell>{set.type}</TableCell>
                                <TableCell>{set.releaseDate || 'N/A'}</TableCell>
                                <TableCell>{set.setNumber}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PokemonSetEditDialog 
                open={dialogOpen}
                onClose={handleClose}
                onSave={handleSave}
                values={editedValues}
                onChange={handleChange}
            />
        </>
    );
};

export default React.memo(PokemonSetManagerTable); 