import { toast } from 'react-toastify';
import config from '../routes/config';
import { SeriesSummary } from '../types/collection';

const fetchConfig = {
    credentials: 'include' as RequestCredentials,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

interface UserApiResponse {
    success: boolean;
    exists?: boolean;
    message?: string;
}

interface FavoritePokemonResponse extends UserApiResponse {
    favoritePokemon?: {
        name: string;
        image: string;
        number: number;
        gen: number;
    };
}

interface UserDetailsResponse extends UserApiResponse {
    userDetails?: any;
}

interface AdminCheckResponse extends UserApiResponse {
    isAdmin?: boolean;
}

interface TypeBreakdown {
    Fire: number;
    Grass: number;
    Lightning: number;
    Psychic: number;
    Fighting: number;
    Water: number;
    Dark: number;
    Metal: number;
    Colorless: number;
}

interface UserCollectionStats {
    current_collection_value: number;
    current_number_of_cards: number;
    current_completed_sets: number;
    number_of_pokemon: number;
    number_of_trainers: number;
    number_of_energy: number;
    type_breakdown: TypeBreakdown;
}

interface CollectionStatsResponse {
    success: boolean;
    stats?: UserCollectionStats;
    message?: string;
}

interface CollectionSummaryResponse {
    success: boolean;
    summary?: {
        series: SeriesSummary[];
    };
    message?: string;
}

export const checkUsername = async (userName: string): Promise<UserApiResponse> => {
    try {
        const queryString = new URLSearchParams({ userName }).toString();
        const response = await fetch(`${config.API_URL}/checkUsername/?${queryString}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: UserApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to check username');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while checking username');
        return { success: false, exists: false };
    }
};

export const updateUserCountry = async (
    authParams: any,
    countryCode: string
): Promise<UserApiResponse> => {
    try {
        const response = await fetch(`${config.API_URL}/updateUserCountry`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...authParams,
                countryCode,
            }),
        });

        const result: UserApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update country');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating country');
        return { success: false };
    }
};

export const getUserDetails = async (
    authParams: any,
    username: string
): Promise<UserDetailsResponse> => {
    try {
        const queryParams = new URLSearchParams({
            ...(authParams && Object.fromEntries(
                Object.entries(authParams).map(([key, value]) => [key, String(value)])
            )),
            userName: username,
        }).toString();

        const response = await fetch(`${config.API_URL}/getUserDetails?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });
        const result: UserDetailsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch user details');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching user details');
        return { success: false };
    }
};

export const getFavoritePokemon = async (pokemonId: number): Promise<FavoritePokemonResponse> => {
    try {
        const response = await fetch(`${config.API_URL}/getPokemonDetails/${pokemonId}`, {
            ...fetchConfig,
            method: 'GET'
        });

        const data = await response.json();

        if (response.ok) {
            return { success: true, favoritePokemon: data };
        } else {
            return { success: false, message: data.message || 'Failed to fetch favorite Pokemon' };
        }
    } catch (error) {
        return { success: false, message: 'An error occurred while fetching favorite Pokemon' };
    }
};

export const updateFavoritePokemon = async (
    authParams: any,
    pokemonId: number
): Promise<UserApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch(`${config.API_URL}/updateFavoritePokemon`, {
            method: 'POST',
            ...fetchConfig,
            body: JSON.stringify({
                ...stringAuthParams,
                pokemonId,
            }),
        });

        const result: UserApiResponse = await response.json();

        if (result.success) {
            toast.success('Favorite Pokémon updated successfully');
        } else {
            toast.error('Failed to update favorite Pokémon');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while updating favorite Pokémon');
        return { success: false };
    }
};

export const checkAdminStatus = async (authParams: any): Promise<AdminCheckResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
        }).toString();

        const response = await fetch(`${config.API_URL}/checkAdminStatus?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: AdminCheckResponse = await response.json();

        if (!result.success) {
        }
        return result;
    } catch (error) {
        return { success: false, isAdmin: false };
    }
};

export const getUserCollectionStats = async (
    userName: string
): Promise<CollectionStatsResponse> => {
    try {
        const queryString = new URLSearchParams({ userName }).toString();
        const response = await fetch(`${config.API_URL}/getUserCollectionStats?${queryString}`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: CollectionStatsResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch collection stats');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching collection stats');
        return { success: false, message: 'Failed to fetch stats' };
    }
};

export const getUserCollectionSummary = async (
    userName: string
): Promise<CollectionSummaryResponse> => {
    try {
        const queryString = new URLSearchParams({ userName }).toString();
        const response = await fetch(`${config.API_URL}/getUserCollectionSummary?${queryString}`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: CollectionSummaryResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch collection summary');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching collection summary');
        return { success: false, message: 'Failed to fetch summary' };
    }
};
