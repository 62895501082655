import React, { useCallback } from 'react';
import { Grid, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { PKRoute } from './RouteManager';

interface RouteEditFormProps {
    values: Partial<PKRoute>;
    onChange: (field: keyof PKRoute, value: any) => void;
}

const RouteEditForm = React.memo(({ values, onChange }: RouteEditFormProps) => {
    console.log('RouteEditForm render with values:', values);
    
    const handleFieldChange = useCallback((field: keyof PKRoute, value: any) => {
        console.log('RouteEditForm handleFieldChange:', { field, value });
        onChange(field, value);
    }, [onChange]);

    return (
        <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
                <TextField
                    label="Parent Route ID"
                    type="number"
                    value={values.parent_route_id || ''}
                    onChange={(e) => handleFieldChange('parent_route_id', e.target.value ? Number(e.target.value) : null)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Path"
                    value={values.path || ''}
                    onChange={(e) => handleFieldChange('path', e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Route Name"
                    value={values.route_name || ''}
                    onChange={(e) => handleFieldChange('route_name', e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Display Name"
                    value={values.display_name || ''}
                    onChange={(e) => handleFieldChange('display_name', e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Component Name"
                    value={values.component_name || ''}
                    onChange={(e) => handleFieldChange('component_name', e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="State"
                    value={values.state || ''}
                    onChange={(e) => handleFieldChange('state', e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="TCG State"
                    value={values.tcg_state || ''}
                    onChange={(e) => handleFieldChange('tcg_state', e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Display Order"
                    type="number"
                    value={values.display_order || ''}
                    onChange={(e) => handleFieldChange('display_order', Number(e.target.value))}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!values.is_active}
                                    onChange={(e) => handleFieldChange('is_active', e.target.checked)}
                                />
                            }
                            label="Is Active"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!values.is_hidden}
                                    onChange={(e) => handleFieldChange('is_hidden', e.target.checked)}
                                />
                            }
                            label="Is Hidden"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!values.is_menu}
                                    onChange={(e) => handleFieldChange('is_menu', e.target.checked)}
                                />
                            }
                            label="Is Menu"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!values.is_set}
                                    onChange={(e) => handleFieldChange('is_set', e.target.checked)}
                                />
                            }
                            label="Is Set"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!values.requires_auth}
                                    onChange={(e) => handleFieldChange('requires_auth', e.target.checked)}
                                />
                            }
                            label="Requires Auth"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!values.requires_admin}
                                    onChange={(e) => handleFieldChange('requires_admin', e.target.checked)}
                                />
                            }
                            label="Requires Admin"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Icon"
                    value={values.icon || ''}
                    onChange={(e) => handleFieldChange('icon', e.target.value)}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
}, (prevProps, nextProps) => {
    console.log('RouteEditForm memo comparison:', {
        prev: prevProps.values,
        next: nextProps.values,
        areEqual: JSON.stringify(prevProps.values) === JSON.stringify(nextProps.values)
    });
    return JSON.stringify(prevProps.values) === JSON.stringify(nextProps.values);
});

RouteEditForm.displayName = 'RouteEditForm';

export default RouteEditForm; 