import { useState, useEffect, useCallback } from 'react';
import * as userApi from '../api/userApi';
import { UserCollectionStats } from '../types/collection';

export const useCollectionStats = (userName: string) => {
    const [stats, setStats] = useState<UserCollectionStats | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchStats = useCallback(async () => {
        if (!userName) {
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await userApi.getUserCollectionStats(userName);
            
            if (response.success && response.stats) {
                setStats(response.stats as UserCollectionStats);
            } else {
                setError(response.message || 'Failed to fetch collection stats');
            }
        } catch (err) {
            setError('An error occurred while fetching collection stats');
        } finally {
            setLoading(false);
        }
    }, [userName]);

    useEffect(() => {
        fetchStats();
    }, [fetchStats]);

    return { stats, loading, error, refetch: fetchStats };
}; 