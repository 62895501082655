import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
    page: string;
    printRun: string;
    setName: string;
    seriesName: string;
    setNumberOfCards: string;
    userSetCollectedCards: number;
    TCGState: string;
    appState: string;
}

const initialState: AppState = {
    page: '',
    printRun: '',
    setName: '',
    seriesName: '',
    setNumberOfCards: '',
    userSetCollectedCards: 0,
    TCGState: 'English',
    appState: '',
};

export const appStateSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setAppState: (state, action: PayloadAction<string>) => {
            state.appState = action.payload;
        },
        setTCGState: (state, action: PayloadAction<string>) => {
            state.TCGState = action.payload;
        },
        setPage: (state, action: PayloadAction<string>) => {
            state.page = action.payload;
        },
        setPrintRun: (state, action: PayloadAction<string>) => {
            state.printRun = action.payload;
        },
        setSetName: (state, action: PayloadAction<string>) => {
            state.setName = action.payload;
        },
        setSeriesName: (state, action: PayloadAction<string>) => {
            state.seriesName = action.payload;
        },
        setSetNumberOfCards: (state, action: PayloadAction<string>) => {
            state.setNumberOfCards = action.payload;
        },
        setUserSetCollectedCards: (state, action: PayloadAction<number>) => {
            state.userSetCollectedCards = action.payload;
        },
    },
});

export const {
    setAppState,
    setTCGState,
    setPage,
    setPrintRun,
    setSetName,
    setSeriesName,
    setSetNumberOfCards,
    setUserSetCollectedCards,
} = appStateSlice.actions;

export default appStateSlice.reducer;
