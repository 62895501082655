// React and related imports
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';

// Material-UI
import { useMediaQuery, Fab, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Third-party libraries
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Custom hooks and contexts
import { useAuth } from '../../../context/AuthContext';

// Utility functions and data
import {
    handleAPIResult,
    addCardToCollection,
    removeCardFromCollection,
} from '../../../components/common/reuseablethings';
import assets from '../../../assets';
import defaultCards from '../../../configs/default_cards';
import { defaultTypes } from '../../../data/defaultTypes';
import { defaultRarities } from '../../../data/defaultRarities';

// Component imports
import CardTemplate from './CardTemplate';
import DesktopFilterLayout from './DesktopFilterLayout';
import MobileFilterLayout from './MobileFilterLayout';
import DesktopProgressDisplay from './DesktopProgressDisplay';
import MobileProgressDisplay from './MobileProgressDisplay';
import DesktopSetInfoHeader from './DesktopSetInfoHeader';
import MobileSetInfoHeader from './MobileSetInfoHeader';
import ToggleButtons from './ToggleButtons';
import DesktopSetCardDisplay from './DesktopSetCardDisplay';
import MobileSetCardDisplay from './MobileSetCardDisplay';

// Styles
import '../../../css/filterSection.css';
import config from '../../../routes/config';

interface SetInfo {
    tcgplayerlink: any;
    type: string;
    setName: string;
    setSeries: string;
    setCardCounts: {
        numberOfCards: number;
        mainSetWithSecretRares: number;
        setNumber: number;
        numOfReverseSet: number;
        numberOfSubSet: number;
        pr_first_edition_count?: number;
        pr_shadowless_count?: number;
        pr_unlimited_count?: number;
        pr_fourth_print_count?: number;
    };
    showPriceData: boolean;
    releaseDate: string;
    showAllCards: boolean;
    logo: string;
    icon: string;
    setPrintRuns?: {
        firstEditionSet?: {
            buttonDisplay: string;
            description: string;
            count: number;
        };
        shadowlessSet?: {
            buttonDisplay: string;
            description: string;
            count: number;
        };
        unlimitedEditionSet?: {
            buttonDisplay: string;
            description: string;
            count: number;
        };
        fourthPrintSet?: {
            buttonDisplay: string;
            description: string;
            count: number;
        };
    };
    includesReverseHolographicCards?: boolean;
}

interface Props {
    setInfo: SetInfo;
}

type CheckBoxValues = {
    have: boolean;
    need: boolean;
    rarity: { [key: number]: boolean };
    type: { [key: number]: boolean };
};

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

const EnglishTemplate: React.FC<Props> = ({ setInfo }) => {
    ReactGA.initialize('G-677WMVXWLS');
    const location = useLocation();

    // Debug logging useEffect
    useEffect(() => {
        // console.group('SetInfo Header Debug');
        // console.log('Full setInfo:', setInfo);
        // console.log('Logo path:', setInfo?.logo);
        // console.log('Set name:', setInfo?.setName);
        // console.log('Set series:', setInfo?.setSeries);
        // console.log('Release date:', setInfo?.releaseDate);
        // console.log('Series:', setInfo?.setSeries);
        // console.groupEnd();
    }, [setInfo]);

    // Route tracking useEffect
    useEffect(() => {
        // console.group('Route Information');
        // console.log('Current Location:', location);
        // console.log('Current Path:', location.pathname);
        // console.groupEnd();
    }, [location]);

    const { authParams, isAuthenticated } = useAuth();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery('(max-width:789px)');
    const printRun = useSelector((state: RootState) => state.printRun);
    const TCGState = useSelector((state: RootState) => state.TCGState);

    const [showCardTemplate, setShowCardTemplate] = useState<boolean>(false);
    const [selectedCard, setSelectedCard] = useState<any>(null);
    const [currentView, setCurrentView] = useState('Unlimited');
    const [cards, setCards] = useState(defaultCards);
    const [checkBoxValues, setCheckBoxValues] = useState<CheckBoxValues>({
        have: false,
        need: false,
        rarity: {},
        type: {},
    });

    // Use optional chaining for all setInfo access
    const type = setInfo?.type || 'PokemonCompany';
    const setName = setInfo?.setName || '';
    const seriesName = setInfo?.setSeries || '';
    const mainSet = setInfo?.setCardCounts?.numberOfCards ?? 0;
    const mainSetWithSecretRares = setInfo?.setCardCounts?.mainSetWithSecretRares ?? 0;
    const reverseSet = setInfo?.setCardCounts?.numOfReverseSet ?? 0;
    const subSet = setInfo?.setCardCounts?.numberOfSubSet ?? 0;

    const [showFirstEdition, showShadowlessSet, showfourthPrintSet] = [
        Boolean(setInfo?.setPrintRuns?.firstEditionSet),
        Boolean(setInfo?.setPrintRuns?.shadowlessSet),
        Boolean(setInfo?.setPrintRuns?.fourthPrintSet)
    ];

    const firstEditionSet = showFirstEdition 
        ? setInfo?.setCardCounts?.pr_first_edition_count ?? 0
        : 0;

    const shadowlessSet = showShadowlessSet
        ? setInfo?.setCardCounts?.pr_shadowless_count ?? 0
        : 0;

    const unlimitedSet = setInfo?.setPrintRuns?.unlimitedEditionSet
        ? setInfo?.setCardCounts?.pr_unlimited_count ?? 0
        : 0;

    const fourthPrintSet = showfourthPrintSet
        ? setInfo.setCardCounts?.pr_fourth_print_count ?? 0
        : 0;
        
    let totalSet = 0;

    const handleCheckBoxChange =
        (section: 'have' | 'need' | 'rarity' | 'type', key: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCheckBoxValues((prevValues) => {
                if (section === 'have' || section === 'need') {
                    return {
                        ...prevValues,
                        [section]: event.target.checked,
                    };
                } else {
                    return {
                        ...prevValues,
                        [section]: {
                            ...prevValues[section],
                            [key]: event.target.checked,
                        },
                    };
                }
            });
        };

    const uniqueRarities = useMemo(() => {
        return Array.from(new Set(cards.map((card) => card.rarityId)));
    }, [cards]);

    const uniqueTypes = useMemo(() => {
        const types = new Set(cards.map((card) => card.type_id));
        if (cards.some((card) => card.type_id === 0 || card.type_id === undefined)) {
            types.add(0);
        }
        return Array.from(types);
    }, [cards]);

    const changeCardSelectedStatus = (card: any) => {
        let cardData = { ...card, ...setInfo };
        setSelectedCard(cardData);
        setShowCardTemplate(true);
    };



    const normaliseFirstEditionSet = (value: number) => ((value - 0) * 100) / (firstEditionSet - 0);
    const normaliseShadowlessSet = (value: number) => ((value - 0) * 100) / (shadowlessSet - 0);
    const normaliseUnlimitedSet = (value: number) => ((value - 0) * 100) / (unlimitedSet - 0);
    const normaliseFourthPrint = (value: number) => ((value - 0) * 100) / (fourthPrintSet - 0);

    let normaliseTotal;

    if (type === 'WOTC') {
        totalSet =
            firstEditionSet + shadowlessSet + unlimitedSet + fourthPrintSet + reverseSet + subSet;
        normaliseTotal = (value: number) => ((value - 0) * 100) / (totalSet - 0);
    } else {
        totalSet = mainSet + reverseSet + subSet;
        normaliseTotal = (value: number) => ((value - 0) * 100) / (totalSet - 0);
    }

    useEffect(() => {
        setCurrentView('Unlimited');
        setCheckBoxValues({
            have: false,
            need: false,
            rarity: Object.fromEntries(defaultRarities.map((rarity) => [rarity.id, false])),
            type: Object.fromEntries(defaultTypes.map((type) => [type.id, false])),
        });

        return () => {
            // Code to run when the component is unmounted (optional)
        };
    }, [location.pathname]);

    let showReverseSet = reverseSet > 0 ? true : false;
    let showSubSet = subSet > 0 ? true : false;

    const normaliseMainSet = (value: number) => ((value - 0) * 100) / (mainSet - 0);
    const normaliseReverseSet = (value: number) => ((value - 0) * 100) / (reverseSet - 0);
    const normaliseSubSet = (value: number) => ((value - 0) * 100) / (subSet - 0);

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: 'Set Beta' });
        dispatch({ type: 'SET_PRINTRUN', payload: 'Unlimited' });
        dispatch({ type: 'SET_SETNAME', payload: setName });
        dispatch({ type: 'SET_SERIESNAME', payload: seriesName });
        dispatch({ 
            type: 'SET_SETNUMBEROFCARDS', 
            payload: `${setInfo?.setCardCounts?.setNumber ?? 0}` 
        });
        document.title = `MyPokellection | ${TCGState} | ${seriesName} | ${setName} Set`;
    }, [
        dispatch, 
        setName, 
        seriesName, 
        setInfo?.setCardCounts?.setNumber, 
        TCGState
    ]);

    useEffect(() => {
        let numUserCardsInCollection = cards.filter(
            (card) => card.isCardInCollection === true
        ).length;
        dispatch({ type: 'SET_USERSETCOLLECTEDCARDS', payload: numUserCardsInCollection });
    }, [cards, dispatch]);

    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, [dispatch]);

    useEffect(() => {
        const queryString = new URLSearchParams({
            setName,
            seriesName,
            TCGState,
        }).toString();
        fetch(`${config.API_URL}/getPokemonCards/?${queryString}`)
            .then((response) => response.json())
            .then((data) => {
                const sortedCards = data.sort(
                    (a: { cardsetindex: number }, b: { cardsetindex: number }) =>
                        a.cardsetindex - b.cardsetindex
                );
                setCards(sortedCards.map((card: any) => ({ ...card, isCardInCollection: false })));
            })
            .then(() => {
                if (isAuthenticated && authParams) {
                    const stringAuthParams = Object.fromEntries(
                        Object.entries(authParams).map(([key, value]) => [key, String(value)])
                    );

                    const queryParams = new URLSearchParams(stringAuthParams).toString();

                    const authParamsObj = Object.fromEntries(new URLSearchParams(queryParams));

                    const requestBody = {
                        ...authParamsObj,
                        setName,
                        seriesName,
                        TCGState,
                        printRun,
                    };

                    const queryString = new URLSearchParams(requestBody).toString();
                    fetch(`${config.API_URL}/getUserPokemonCards?${queryString}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data: { id: number }[]) => {
                            const updatedCards = data.map((card) => ({
                                ...card,
                                isCardInCollection: true,
                            }));
                            setCards((prevCards) => {
                                const newCards = prevCards.map((c) => {
                                    const updatedCard = updatedCards.find((uc) => uc.id === c.id);
                                    return updatedCard
                                        ? {
                                              ...c,
                                              isCardInCollection: updatedCard.isCardInCollection,
                                          }
                                        : c;
                                });
                                return newCards;
                            });
                        })
                        .catch((error) => {
                            handleAPIResult({
                                success: false,
                                failed: true,
                                message: 'Failed: Unable to retrieve user cards',
                            });
                        });
                }
            })
            .catch((error) => console.error('Error in outer fetch:', error));
    }, [isAuthenticated, authParams, printRun, TCGState, seriesName, setName, currentView]);

    useEffect(() => {
        // Add this block to update rarity checkboxes
        const uniqueRarities = Array.from(
            new Set(cards.map((card) => card.rarityId?.toString() || '').filter(Boolean))
        );
        setCheckBoxValues((prev) => ({
            ...prev,
            rarity: Object.fromEntries(uniqueRarities.map((rarityId) => [rarityId, false])),
        }));
    }, [cards]);

    function changeCardCollectionStatus(
        cards: any[],
        card: {
            id: number;
            cardsetindex: number;
            number: number;
            name: string;
            imagelocation: string;
            isCardInCollection: boolean;
            market_price: number;
        }
    ): void {
        if (!isAuthenticated) {
            return;
        }

        const cardToUpdate = cards.find((c) => c.id === card.id);
        if (cardToUpdate && authParams) {
            const updatedCard = {
                ...cardToUpdate,
                isCardInCollection: !cardToUpdate.isCardInCollection,
            };
            setCards(cards.map((c) => (c.id === card.id ? updatedCard : c)));

            const stringAuthParams = Object.fromEntries(
                Object.entries(authParams).map(([key, value]) => [key, String(value)])
            );
            const queryParams = new URLSearchParams(stringAuthParams).toString();

            if (!cardToUpdate.isCardInCollection) {
                addCardToCollection(cardToUpdate.id, queryParams);
            } else {
                removeCardFromCollection(cardToUpdate.id, queryParams);
            }
        }
    }

    // Instead, create a sorted copy of the cards array
    const sortedCards = [...cards].sort((a, b) => a.cardsetindex - b.cardsetindex);

    let collectedFirstEditionSet = sortedCards.filter(
        (card) => card.isCardInCollection && card.editionId === 1
    ).length;
    let collectedShadowlessSet = sortedCards.filter(
        (card) => card.isCardInCollection && card.editionId === 2
    ).length;
    let collectedUnlimitedSet = sortedCards.filter(
        (card) =>
            card.isCardInCollection &&
            card.editionId === 3 &&
            !card.isReverseHolo &&
            !card.isPartOfSubSet
    ).length;
    let collectedFourthPrint = sortedCards.filter(
        (card) => card.isCardInCollection && card.editionId === 4
    ).length;

    let collectedMainSet = sortedCards.filter(
        (card) => card.isCardInCollection && !card.isReverseHolo && !card.isPartOfSubSet
    ).length;
    let collectedReverseHoloSet = sortedCards.filter(
        (card) => card.isCardInCollection && card.isReverseHolo && !card.isPartOfSubSet
    ).length;
    let collectedSubSet = sortedCards.filter(
        (card) => card.editionId === 3 && card.isCardInCollection && card.isPartOfSubSet
    ).length;
    let collectedCards;

    if (type === 'WOTC') {
        collectedCards =
            collectedFirstEditionSet +
            collectedShadowlessSet +
            collectedUnlimitedSet +
            collectedFourthPrint;
    } else {
        collectedCards = collectedMainSet + collectedReverseHoloSet + collectedSubSet;
    }

    // This useEffect will scroll to top only when setInfo.setName changes
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [setInfo?.setName]);

    // Modified handleToggle function without scroll-to-top action
    const handleToggle = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        if (newAlignment !== null) {
            // console.group('Toggle Button Debug');
            // console.log('Current View Changed:', {
            //     from: currentView,
            //     to: newAlignment,
            //     setType: type,
            //     showAllCards: setInfo?.showAllCards,
            //     totalCards: sortedCards.length
            // });

            // Log what should be filtered based on the conditions
            if (type === 'WOTC' && setInfo?.showAllCards !== true) {
                const editionMap: Record<string, number> = {
                    'First Edition': 1,
                    'Shadowless': 2,
                    'Unlimited': 3,
                    '4th Print': 4
                };
                
                const expectedCount = newAlignment === 'All Cards' 
                    ? sortedCards.length
                    : sortedCards.filter(card => card.editionId === editionMap[newAlignment]).length;

                console.log('WOTC Filtering:', {
                    filterType: 'editionId',
                    expectedEditionId: editionMap[newAlignment],
                    expectedCardCount: expectedCount,
                    filterCondition: newAlignment === 'All Cards' 
                        ? 'No edition filter - showing all cards'
                        : `Cards with editionId === ${editionMap[newAlignment]}`
                });
            } else {
                const filterConditions = {
                    'Unlimited': 'Not reverse holo and not part of subset',
                    'Reverse Set': 'Is reverse holo and not part of subset',
                    'Sub-Set': 'Is part of subset',
                    'All Cards': 'No filters - showing all cards'
                };

                const expectedCount = (() => {
                    switch(newAlignment) {
                        case 'Unlimited':
                            return sortedCards.filter(card => !card.isReverseHolo && !card.isPartOfSubSet).length;
                        case 'Reverse Set':
                            return sortedCards.filter(card => card.isReverseHolo && !card.isPartOfSubSet).length;
                        case 'Sub-Set':
                            return sortedCards.filter(card => card.isPartOfSubSet).length;
                        case 'All Cards':
                            return sortedCards.length;
                        default:
                            return 0;
                    }
                })();

                console.log('Regular Set Filtering:', {
                    filterType: 'setType',
                    filterCondition: filterConditions[newAlignment as keyof typeof filterConditions],
                    expectedCardCount: expectedCount
                });
            }

            setCurrentView(newAlignment);
            console.groupEnd();
        }
    };

    // Update cardsToDisplay to use sortedCards
    let cardsToDisplay = sortedCards;

    if (type === 'WOTC' && setInfo?.showAllCards !== true) {
        if (currentView === 'First Edition') {
            cardsToDisplay = cardsToDisplay.filter((card) => card.editionId === 1);
        } else if (currentView === 'Shadowless') {
            cardsToDisplay = cardsToDisplay.filter((card) => card.editionId === 2);
        } else if (currentView === 'Unlimited') {
            if (setInfo?.includesReverseHolographicCards === true) {
                cardsToDisplay = cardsToDisplay.filter((card) => card.editionId === 3 && !card.isReverseHolo && !card.isPartOfSubSet);
            } else {
                cardsToDisplay = cardsToDisplay.filter((card) => card.editionId === 3 && !card.isPartOfSubSet);
            }
        } else if (currentView === '4th Print') {
            cardsToDisplay = cardsToDisplay.filter((card) => card.editionId === 4);
        } else if (currentView === 'Reverse Set') {
            if (setInfo?.includesReverseHolographicCards === true) {
                cardsToDisplay = cardsToDisplay.filter((card) => card.editionId === 3 && card.isReverseHolo && !card.isPartOfSubSet);
            } else {
                cardsToDisplay = cardsToDisplay.filter((card) => card.editionId === 3 && !card.isPartOfSubSet);
            }
        } else if (currentView === 'Sub-Set') {
            cardsToDisplay = cardsToDisplay.filter((card) => card.editionId === 3 && card.isPartOfSubSet);
        }
    } else if (setInfo?.showAllCards === true) {
        if (currentView === 'Unlimited') {
            cardsToDisplay = cardsToDisplay.filter((card) => !card.isReverseHolo && !card.isPartOfSubSet);
        } else if (currentView === 'Reverse Set') {
            cardsToDisplay = cardsToDisplay.filter((card) => card.isReverseHolo && !card.isPartOfSubSet);
        } else if (currentView === 'Sub-Set') {
            cardsToDisplay = cardsToDisplay.filter((card) => card.isPartOfSubSet);
        } else if (currentView === 'All Cards') {
            // Don't apply any filters when showing all cards
            cardsToDisplay = sortedCards;
        }
    } else {
        if (currentView === 'Unlimited') {
            cardsToDisplay = cardsToDisplay.filter((card) => !card.isReverseHolo && !card.isPartOfSubSet);
        } else if (currentView === 'Reverse Set') {
            cardsToDisplay = cardsToDisplay.filter((card) => card.isReverseHolo && !card.isPartOfSubSet);
        } else if (currentView === 'Sub-Set') {
            cardsToDisplay = cardsToDisplay.filter((card) => card.isPartOfSubSet);
        } else if (currentView === 'All Cards') {
            // Don't apply any filters when showing all cards
            cardsToDisplay = sortedCards;
        }
    }

    if (checkBoxValues.have && !checkBoxValues.need) {
        cardsToDisplay = cardsToDisplay.filter((card) => card.isCardInCollection);
    } else if (checkBoxValues.need && !checkBoxValues.have) {
        cardsToDisplay = cardsToDisplay.filter((card) => !card.isCardInCollection);
    }

    // Filter by rarity
    const selectedRarityIds = Object.entries(checkBoxValues.rarity)
        .filter(([_, isSelected]) => isSelected)
        .map(([rarityId]) => parseInt(rarityId));

    if (selectedRarityIds.length > 0) {
        cardsToDisplay = cardsToDisplay.filter((card) => selectedRarityIds.includes(card.rarityId));
    }

    // Filter by type
    const selectedTypeIds = Object.entries(checkBoxValues.type)
        .filter(([_, isSelected]) => isSelected)
        .map(([typeId]) => parseInt(typeId));

    if (selectedTypeIds.length > 0) {
        cardsToDisplay = cardsToDisplay.filter((card) => selectedTypeIds.includes(card.type_id));
    }

    const defaultLink = 'https://partner.tcgplayer.com/c/5054575/1780961/21018?u=https://www.tcgplayer.com/categories/trading-and-collectible-card-games/pokemon';
    let dynamicLink = '';
    if (setInfo?.tcgplayerlink) {
        dynamicLink = `https://partner.tcgplayer.com/c/5054575/1780961/21018?u=${encodeURIComponent(setInfo.tcgplayerlink)}`;
    } else {
        dynamicLink = defaultLink;
    }

    // Calculate the total filtered cards
    const totalFilteredCards = useMemo(() => {
        return cardsToDisplay.length;
    }, [cardsToDisplay]);

    // Add debug logging
    // useEffect(() => {
    //     console.group('TemplateSet Debug');
    //     console.log('Full setInfo:', setInfo);
    //     if (setInfo?.setCardCounts) {
    //         console.log('Card Counts:', {
    //             mainSet: setInfo.setCardCounts.numberOfCards,
    //             reverseSet: setInfo.setCardCounts.numOfReverseSet,
    //             subSet: setInfo.setCardCounts.numberOfSubSet,
    //             setNumber: setInfo.setCardCounts.setNumber,
    //             mainSetWithSecretRares: setInfo.setCardCounts.mainSetWithSecretRares
    //         });
    //     } else {
    //         console.warn('setCardCounts is undefined');
    //     }
    //     console.log('Includes Reverse:', setInfo?.includesReverseHolographicCards);
    //     console.groupEnd();
    // }, [setInfo]);

    // useEffect(() => {
    //     console.group('Filtered Cards Result');
    //     console.log('Current View:', currentView);
    //     console.log('Filtered Card Count:', cardsToDisplay.length);
    //     console.log('Filter Results:', {
    //         totalCards: sortedCards.length,
    //         filteredCards: cardsToDisplay.length,
    //         currentView,
    //         isWOTC: type === 'WOTC',
    //         showAllCards: setInfo?.showAllCards
    //     });
    //     console.groupEnd();
    // }, [cardsToDisplay.length, currentView, sortedCards.length, type, setInfo?.showAllCards]);

    useEffect(() => {
        if (type === 'WOTC') {
            console.group('WOTC Set Numbers');
            console.log('First Edition:', {
                count: setInfo?.setCardCounts?.pr_first_edition_count,
                collected: collectedFirstEditionSet
            });
            console.log('Shadowless:', {
                count: setInfo?.setCardCounts?.pr_shadowless_count,
                collected: collectedShadowlessSet
            });
            console.log('Unlimited:', {
                count: setInfo?.setCardCounts?.pr_unlimited_count,
                collected: collectedUnlimitedSet
            });
            console.log('4th Print:', {
                count: setInfo?.setCardCounts?.pr_fourth_print_count,
                collected: collectedFourthPrint
            });
            console.log('Total Set:', totalSet);
            console.groupEnd();
        }
    }, [
        type,
        setInfo?.setCardCounts,
        collectedFirstEditionSet,
        collectedShadowlessSet,
        collectedUnlimitedSet,
        collectedFourthPrint,
        totalSet
    ]);

    // useEffect(() => {
    //     console.group('Price Data Debug - Full Trace');
    //     console.log('Raw setInfo:', setInfo);
    //     console.log('Price Data Flags:', {
    //         rawShowPriceData: setInfo?.showPriceData,
    //         booleanConversion: Boolean(setInfo?.showPriceData),
    //         type: typeof setInfo?.showPriceData
    //     });
    //     console.log('API Response Sample Card:', {
    //         firstCard: cardsToDisplay[0],
    //         hasMarketPrice: cardsToDisplay[0]?.market_price !== undefined,
    //         marketPriceValue: cardsToDisplay[0]?.market_price
    //     });
    //     console.groupEnd();
    // }, [setInfo, cardsToDisplay]);

    // Early return if setInfo is not available
    if (!setInfo) {
        console.warn('No setInfo provided to TemplateSet');
        return <div>Loading...</div>;
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {showCardTemplate && selectedCard && (
                <CardTemplate cardInfo={selectedCard} onClose={() => setShowCardTemplate(false)} />
            )}

            {isMobile ? (
                <>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileSetInfoHeader
                            setInfo={{
                                logo: setInfo?.logo || '',
                                icon: setInfo?.icon || '',
                                setName: setInfo?.setName,
                                releaseDate: setInfo?.releaseDate ?? '',
                                setSeries: setInfo?.setSeries,
                                tcgplayerlink: setInfo?.tcgplayerlink
                            }}
                            dynamicLink={dynamicLink}
                            assets={assets}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileProgressDisplay
                            showFirstEdition={showFirstEdition}
                            showShadowlessSet={showShadowlessSet}
                            showfourthPrintSet={showfourthPrintSet}
                            showReverseSet={showReverseSet}
                            showSubSet={showSubSet}
                            type={type}
                            collectedFirstEditionSet={collectedFirstEditionSet}
                            firstEditionSet={setInfo?.setCardCounts?.pr_first_edition_count ?? 0}
                            collectedShadowlessSet={collectedShadowlessSet}
                            shadowlessSet={setInfo?.setCardCounts?.pr_shadowless_count ?? 0}
                            collectedUnlimitedSet={collectedUnlimitedSet}
                            unlimitedSet={setInfo?.setCardCounts?.pr_unlimited_count ?? 0}
                            collectedMainSet={collectedMainSet}
                            mainSet={mainSet}
                            collectedFourthPrint={collectedFourthPrint}
                            fourthPrintSet={setInfo?.setCardCounts?.pr_fourth_print_count ?? 0}
                            collectedReverseHoloSet={collectedReverseHoloSet}
                            reverseSet={reverseSet}
                            collectedSubSet={collectedSubSet}
                            subSet={subSet}
                            collectedCards={collectedCards}
                            totalSet={totalSet}
                            normaliseFirstEditionSet={normaliseFirstEditionSet}
                            normaliseShadowlessSet={normaliseShadowlessSet}
                            normaliseUnlimitedSet={normaliseUnlimitedSet}
                            normaliseMainSet={normaliseMainSet}
                            normaliseFourthPrint={normaliseFourthPrint}
                            normaliseReverseSet={normaliseReverseSet}
                            normaliseSubSet={normaliseSubSet}
                            normaliseTotal={normaliseTotal}
                            currentView={currentView}
                            mainSetWithSecretRares={mainSetWithSecretRares}
                            numberOfCards={setInfo?.setCardCounts?.numberOfCards}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileFilterLayout
                            checkBoxValues={checkBoxValues}
                            handleCheckBoxChange={handleCheckBoxChange}
                            uniqueRarities={uniqueRarities}
                            uniqueTypes={uniqueTypes}
                            totalFilteredCards={totalFilteredCards}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopSetInfoHeader
                            setInfo={{
                                logo: setInfo?.logo ?? '',
                                icon: setInfo?.icon ?? '',
                                setName: setInfo?.setName,
                                releaseDate: setInfo?.releaseDate ?? '',
                                setSeries: setInfo?.setSeries,
                                tcgplayerlink: setInfo?.tcgplayerlink
                            }}
                            dynamicLink={dynamicLink}
                            assets={assets}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopProgressDisplay
                            showFirstEdition={showFirstEdition}
                            showShadowlessSet={showShadowlessSet}
                            showfourthPrintSet={showfourthPrintSet}
                            showReverseSet={showReverseSet}
                            showSubSet={showSubSet}
                            type={type}
                            collectedFirstEditionSet={collectedFirstEditionSet}
                            firstEditionSet={setInfo?.setCardCounts?.pr_first_edition_count ?? 0}
                            collectedShadowlessSet={collectedShadowlessSet}
                            shadowlessSet={setInfo?.setCardCounts?.pr_shadowless_count ?? 0}
                            collectedUnlimitedSet={collectedUnlimitedSet}
                            unlimitedSet={setInfo?.setCardCounts?.pr_unlimited_count ?? 0}
                            collectedMainSet={collectedMainSet}
                            mainSet={mainSet}
                            setNumber={setInfo?.setCardCounts?.setNumber}   
                            mainSetWithSecretRares={mainSetWithSecretRares}
                            collectedFourthPrint={collectedFourthPrint}
                            fourthPrintSet={setInfo?.setCardCounts?.pr_fourth_print_count ?? 0}
                            collectedReverseHoloSet={collectedReverseHoloSet}
                            reverseSet={reverseSet}
                            collectedSubSet={collectedSubSet}
                            subSet={subSet}
                            collectedCards={collectedCards}
                            totalSet={totalSet}
                            numberOfCards={setInfo?.setCardCounts?.numberOfCards}
                            normaliseFirstEditionSet={normaliseFirstEditionSet}
                            normaliseShadowlessSet={normaliseShadowlessSet}
                            normaliseUnlimitedSet={normaliseUnlimitedSet}
                            normaliseMainSet={normaliseMainSet}
                            normaliseFourthPrint={normaliseFourthPrint}
                            normaliseReverseSet={normaliseReverseSet}
                            normaliseSubSet={normaliseSubSet}
                            normaliseTotal={normaliseTotal}
                            currentView={currentView}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopFilterLayout
                            checkBoxValues={checkBoxValues}
                            handleCheckBoxChange={handleCheckBoxChange}
                            uniqueRarities={uniqueRarities}
                            uniqueTypes={uniqueTypes}
                            totalFilteredCards={totalFilteredCards}
                        />
                    </div>
                </>
            )}

            <div style={{ marginBottom: '5px' }}>
                <ToggleButtons
                    setInfo={setInfo}
                    select={currentView}
                    handleToggle={handleToggle}
                    showFirstEdition={showFirstEdition}
                    showShadowlessSet={showShadowlessSet}
                    showfourthPrintSet={showfourthPrintSet}
                    showReverseSet={showReverseSet}
                    showSubSet={showSubSet}
                    type={type}
                />
            </div>

            <div>
                {isMobile ? (
                    <MobileSetCardDisplay
                        cardsToDisplay={cardsToDisplay}
                        setInfo={setInfo}
                        assets={assets}
                        showPriceData={setInfo?.showPriceData || false}
                        changeCardSelectedStatus={changeCardSelectedStatus}
                        changeCardCollectionStatus={changeCardCollectionStatus}
                    />
                ) : (
                    <DesktopSetCardDisplay
                        cardsToDisplay={cardsToDisplay}
                        setInfo={setInfo}
                        assets={assets}
                        showPriceData={setInfo?.showPriceData || false}
                        changeCardSelectedStatus={changeCardSelectedStatus}
                        changeCardCollectionStatus={changeCardCollectionStatus}
                    />
                )}
            </div>

            <ScrollTop />
        </>
    );
};

export default EnglishTemplate;
