import { Paper, Grid, Skeleton, Tooltip, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import StarIcon from '@mui/icons-material/Star';
import BoltIcon from '@mui/icons-material/Bolt';
import CollectionsIcon from '@mui/icons-material/Collections';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Edit } from '@mui/icons-material';
import { UserCollectionStats, TypeBreakdown } from '../../types/collection';
import assets from '../../assets';

interface CollectionStatsPanelProps {
    userName: string;
    loading: boolean;
    stats?: UserCollectionStats | null;
    error?: string | null;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '20px',
    height: '100%',
}));

const StatBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    height: '100%',
}));

const StatValue = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '8px',
    variant: 'h6'
});

const StatLabel = styled(Typography)({
    fontSize: '0.875rem',
    color: 'text.secondary',
    textAlign: 'center',
    variant: 'subtitle2'
});

const IconWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '8px',
});

const typeIcons: Record<string, string> = {
    Fire: assets.site.images.Fire,
    Grass: assets.site.images.Grass,
    Lightning: assets.site.images.Lightning,
    Psychic: assets.site.images.Psychic,
    Fighting: assets.site.images.Fighting,
    Water: assets.site.images.Water,
    Darkness: assets.site.images.Darkness,
    Metal: assets.site.images.Metal,
    Colorless: assets.site.images.Colorless,
    Dragon: assets.site.images.Dragon,
    Fairy: assets.site.images.Fairy
};

const TypeIcon = styled('img')({
    width: '24px',
    height: '24px',
    objectFit: 'contain'
});

const CollectionStatsPanel: React.FC<CollectionStatsPanelProps> = ({ 
    userName, 
    loading, 
    stats,
    error 
}) => {
    const formatCurrency = (value: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);
    };

    const renderTypeBreakdown = (typeBreakdown: TypeBreakdown) => {
        return (
            <>
                <Typography 
                    variant="h6" 
                    sx={{ 
                        mt: 3, 
                        mb: 2,
                        px: 2
                    }}
                >
                    Card Type Breakdown
                </Typography>
                <Grid 
                    container 
                    spacing={2}
                    sx={{ px: 1 }}
                >
                    {Object.entries(typeBreakdown)
                        .filter(([_, count]) => count > 0)
                        .map(([type, count]) => (
                            <Grid item xs={4} sm={3} md={2} key={type}>
                                <StatBox sx={{ 
                                    flexDirection: 'row', 
                                    justifyContent: 'center',
                                    gap: 1,
                                    py: 1,
                                    px: 2
                                }}>
                                    <IconWrapper sx={{ mb: 0 }}>
                                        <TypeIcon 
                                            src={typeIcons[type]} 
                                            alt={`${type} type`}
                                        />
                                    </IconWrapper>
                                    {loading ? (
                                        <Skeleton width={30} />
                                    ) : (
                                        <Tooltip title={`${type} type Pokémon cards`}>
                                            <StatValue sx={{ mt: 0 }}>{count}</StatValue>
                                        </Tooltip>
                                    )}
                                </StatBox>
                            </Grid>
                        ))}
                </Grid>
            </>
        );
    };

    if (error) {
        return (
            <StyledPaper>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <ErrorOutlineIcon color="error" />
                    <Typography variant="h4" color="error">
                        Error loading collection stats
                    </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                    {error}
                </Typography>
            </StyledPaper>
        );
    }

    return (
        <StyledPaper>
            <Typography 
                variant="h6" 
                sx={{ 
                    mb: 3,
                    px: 2
                }}
            >
                Collection Stats
            </Typography>
            <Grid 
                container 
                spacing={2}
                sx={{ px: 1 }}
            >
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <IconWrapper>
                            <AttachMoneyIcon color="primary" fontSize="large" />
                        </IconWrapper>
                        <StatLabel>Collection Value</StatLabel>
                        {loading ? (
                            <Skeleton width={100} height={40} />
                        ) : (
                            <Tooltip title="Total market value of your collection">
                                <StatValue>
                                    {formatCurrency(stats?.current_collection_value || 0)}
                                </StatValue>
                            </Tooltip>
                        )}
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <IconWrapper>
                            <CollectionsIcon color="primary" fontSize="large" />
                        </IconWrapper>
                        <StatLabel>Total Cards</StatLabel>
                        {loading ? (
                            <Skeleton width={60} height={40} />
                        ) : (
                            <Tooltip title="Total number of cards in your collection">
                                <StatValue>{stats?.current_number_of_cards || 0}</StatValue>
                            </Tooltip>
                        )}
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <IconWrapper>
                            <StarIcon color="primary" fontSize="large" />
                        </IconWrapper>
                        <StatLabel>Completed Sets</StatLabel>
                        {loading ? (
                            <Skeleton width={60} height={40} />
                        ) : (
                            <Tooltip title="Number of completed card sets">
                                <StatValue>{stats?.current_completed_sets || 0}</StatValue>
                            </Tooltip>
                        )}
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <IconWrapper>
                            <CatchingPokemonIcon color="primary" fontSize="large" />
                        </IconWrapper>
                        <StatLabel>Pokémon Cards</StatLabel>
                        {loading ? (
                            <Skeleton width={60} height={40} />
                        ) : (
                            <Tooltip title="Number of Pokémon cards in your collection">
                                <StatValue>{stats?.number_of_pokemon || 0}</StatValue>
                            </Tooltip>
                        )}
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <IconWrapper>
                            <Edit color="primary" fontSize="large" />
                        </IconWrapper>
                        <StatLabel>Trainer Cards</StatLabel>
                        {loading ? (
                            <Skeleton width={60} height={40} />
                        ) : (
                            <Tooltip title="Number of Trainer cards in your collection">
                                <StatValue>{stats?.number_of_trainers || 0}</StatValue>
                            </Tooltip>
                        )}
                    </StatBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StatBox>
                        <IconWrapper>
                            <BoltIcon color="primary" fontSize="large" />
                        </IconWrapper>
                        <StatLabel>Energy Cards</StatLabel>
                        {loading ? (
                            <Skeleton width={60} height={40} />
                        ) : (
                            <Tooltip title="Number of Energy cards in your collection">
                                <StatValue>{stats?.number_of_energy || 0}</StatValue>
                            </Tooltip>
                        )}
                    </StatBox>
                </Grid>
                {stats?.type_breakdown && renderTypeBreakdown(stats.type_breakdown)}
            </Grid>
        </StyledPaper>
    );
};

export default CollectionStatsPanel; 