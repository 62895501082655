import { toast } from 'react-toastify';
import config from '../routes/config';

const fetchConfig = {
    credentials: 'include' as RequestCredentials,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
};

interface Activity {
    id: number;
    activity_type_id: number;
    reference_id: string;
    metadata: string;
    created_at: string;
    display_template: string;
    icon: string;
    pokemon_image?: string;
}

interface ActivityApiResponse {
    success: boolean;
    activities?: Activity[];
    message?: string;
}

export const getUserActivities = async (
    userName: string,
    page: number = 1,
    pageSize: number = 20
): Promise<ActivityApiResponse> => {
    try {
        const queryParams = new URLSearchParams({
            userName,
            page: String(page),
            pageSize: String(pageSize)
        }).toString();

        const response = await fetch(`${config.API_URL}/getUserActivities?${queryParams}`, {
            ...fetchConfig,
            method: 'GET'
        });

        const result: ActivityApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch user activities');
        }

        return result;
    } catch (error) {
        toast.error('An error occurred while fetching user activities');
        return { success: false, message: 'Failed to fetch activities' };
    }
}; 