import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Paper, Stack, Typography, Skeleton, Box, CircularProgress, Avatar } from '@mui/material';
import { useActivityFeed } from '../../hooks/useActivityFeed';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'white',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const ActivityItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[50],
  marginBottom: theme.spacing(2),
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateX(4px)',
    boxShadow: theme.shadows[1],
  },
  '&:last-child': {
    marginBottom: 0,
  },
}));

const ActivityIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 50,
  height: 50,
  marginRight: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    borderRadius: '50%',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: '50px',
    height: '50px'
  },
  '& .friend-icon, & .follow-icon': {
    width: '50px',
    height: '50px',
    padding: theme.spacing(0.5),
  }
}));

const PokemonImage = styled('img')({
  width: '60px',
  height: '60px',
  objectFit: 'contain'
});

const UserLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

interface Activity {
  id: number;
  activity_type_id: number;
  reference_id: string;
  metadata: string;
  created_at: string;
  display_template: string;
  icon: string;
  pokemon_image?: string;
}

interface ActivityFeedPanelProps {
  userName: string;
}

const getActivityIcon = (activity: Activity) => {
  if (activity.pokemon_image) {
    return <PokemonImage src={activity.pokemon_image} alt="Pokemon" />;
  }

  switch (activity.icon) {
    case 'card_add':
      return <AddCircleIcon />;
    case 'set_complete':
      return <EmojiEventsIcon />;
    case 'friend_add':
      return <PersonAddIcon className="friend-icon" />;
    case 'follow_user':
      return <FavoriteIcon className="follow-icon" />;
    case 'profile_update':
      return <EditIcon />;
    case 'milestone_reach':
      return <StarIcon />;
    default:
      return <StarIcon />;
  }
};

const LoadingSkeleton = () => (
  <Stack spacing={2}>
    {[1, 2, 3].map((key) => (
      <ActivityItem key={key}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton variant="circular" width={40} height={40} />
          <Stack spacing={1} width="100%">
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="40%" />
          </Stack>
        </Stack>
      </ActivityItem>
    ))}
  </Stack>
);

const ActivityFeedPanel: React.FC<ActivityFeedPanelProps> = ({ userName }) => {
  const { activities, loading, error } = useActivityFeed(userName);

  const formatActivityMessage = (template: string, metadata: string, username: string) => {
    try {
      // Parse the metadata JSON string into an object
      const parsedMetadata = JSON.parse(metadata);
      // console.log('Activity metadata:', parsedMetadata);
      // console.log('Activity template:', template);

      // Create a link component for the current user's name
      const userLink = <UserLink to={`/u/${username}`}>{username}</UserLink>;

      // Start with the template string (e.g., "{user} added {card_name} from {set_name} set")
      let message = template;

      // First, replace the {user} placeholder with just the username text
      // We'll convert it to a link later
      message = message.replace('{user}', username);

      // Replace all metadata placeholders with their values
      Object.entries(parsedMetadata).forEach(([key, value]) => {
        // Special handling for friend/target usernames - just replace with text for now
        // We'll convert them to links later
        if (key === 'friend' || key === 'target') {
          message = message.replace(`{${key}}`, value as string);
        }
        // Add # prefix to card numbers
        else if (key === 'card_number') {
          message = message.replace(`{${key}}`, `#${value}`);
        }
        // Don't replace set_name yet - we'll handle it specially to make it a link
        else if (key === 'set_name') {
          message = message.replace(`{${key}}`, value as string);
        }
        // Replace all other placeholders with their values
        else {
          message = message.replace(`{${key}}`, value as string);
        }
      });

      // console.log('Message after placeholder replacement:', message);

      // Split the message into words so we can process each part
      const parts = message.split(' ');
      // console.log('Message parts:', parts);

      // Find key positions in the message to help identify set names
      const fromIndex = parts.indexOf('from');       // For "from {set_name} set"
      const setIndex = parts.indexOf('set');         // For both patterns
      const completedIndex = parts.indexOf('completed'); // For "completed {set_name} set"

      // Process each word and convert usernames/set names to links where appropriate
      return parts.map((part, index) => {
        // Convert current user's name to a link
        if (part === username) {
          return <React.Fragment key={index}>{userLink}{' '}</React.Fragment>;
        }
        // Convert friend's name to a link
        else if (parsedMetadata.friend && part === parsedMetadata.friend) {
          return <React.Fragment key={index}>
            <UserLink to={`/u/${part}`}>{part}</UserLink>{' '}
          </React.Fragment>;
        }
        // Convert target user's name to a link
        else if (parsedMetadata.target && part === parsedMetadata.target) {
          return <React.Fragment key={index}>
            <UserLink to={`/u/${part}`}>{part}</UserLink>{' '}
          </React.Fragment>;
        }
        // Handle single-word set names (exact match)
        else if (parsedMetadata.set_path && parsedMetadata.set_name && part === parsedMetadata.set_name) {
          return <React.Fragment key={index}>
            <UserLink to={parsedMetadata.set_path}>{parsedMetadata.set_name}</UserLink>{' '}
          </React.Fragment>;
        }
        // Handle multi-word set names in "from {set_name} set" pattern
        else if (parsedMetadata.set_path && fromIndex !== -1 && setIndex !== -1 && 
                 index > fromIndex && index < setIndex) {
          // Only process the first word after "from" - include all words up to "set"
          if (index === fromIndex + 1) {
            const setNameWords = parts.slice(fromIndex + 1, setIndex).join(' ');
            return <React.Fragment key={index}>
              <UserLink to={parsedMetadata.set_path}>{setNameWords}</UserLink>{' '}
            </React.Fragment>;
          }
          // Skip other words that are part of the set name
          else {
            return null;
          }
        }
        // Handle multi-word set names in "completed {set_name} set" pattern
        else if (parsedMetadata.set_path && completedIndex !== -1 && setIndex !== -1 && 
                 index > completedIndex && index < setIndex) {
          // Only process the first word after "completed" - include all words up to "set"
          if (index === completedIndex + 1) {
            const setNameWords = parts.slice(completedIndex + 1, setIndex).join(' ');
            return <React.Fragment key={index}>
              <UserLink to={parsedMetadata.set_path}>{setNameWords}</UserLink>{' '}
            </React.Fragment>;
          }
          // Skip other words that are part of the set name
          else {
            return null;
          }
        }
        // Return all other words unchanged
        return <React.Fragment key={index}>{part}{' '}</React.Fragment>;
      }).filter(Boolean); // Remove null entries from skipped set name words
    } catch (error) {
      console.error('Error formatting activity message:', error);
      return 'Activity occurred';
    }
  };

  const formatTimeAgo = (timestamp: string) => {
    try {
      const date = parseISO(timestamp);
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Error formatting timestamp:', error, timestamp);
      return 'some time ago';
    }
  };

  if (loading) {
    return (
      <StyledPaper>
        <Typography variant="h6" gutterBottom>Activity Feed</Typography>
        <LoadingSkeleton />
      </StyledPaper>
    );
  }

  if (error) {
    return (
      <StyledPaper>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6" color="error">Error Loading Activities</Typography>
          <Typography color="text.secondary">{error}</Typography>
        </Stack>
      </StyledPaper>
    );
  }

  if (!activities?.length) {
    return (
      <StyledPaper>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6">No Activities</Typography>
          <Typography color="text.secondary">No activities to display yet.</Typography>
        </Stack>
      </StyledPaper>
    );
  }

  return (
    <StyledPaper>
      <Typography variant="h6" gutterBottom>Activity Feed</Typography>
      <Stack spacing={2}>
        {activities.map((activity) => (
          <ActivityItem key={activity.id}>
            <Stack direction="row" alignItems="center">
              <ActivityIcon>
                {getActivityIcon(activity)}
              </ActivityIcon>
              <Stack spacing={0.5}>
                <Typography>
                  {formatActivityMessage(activity.display_template, activity.metadata, userName)}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {formatTimeAgo(activity.created_at)}
                </Typography>
              </Stack>
            </Stack>
          </ActivityItem>
        ))}
      </Stack>
    </StyledPaper>
  );
};

export default ActivityFeedPanel; 