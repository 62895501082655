import { useState, useEffect } from 'react';
import { getUserActivities } from '../api/activityApi';

interface Activity {
  id: number;
  activity_type_id: number;
  reference_id: string;
  metadata: string;
  created_at: string;
  display_template: string;
  icon: string;
}

export const useActivityFeed = (targetUserName: string) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        setLoading(true);
        setError(null);

        const result = await getUserActivities(targetUserName, 1, 20);

        if (result.success && result.activities) {
          setActivities(result.activities);
        } else {
          throw new Error(result.message || 'Failed to fetch activities');
        }
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'An error occurred';
        console.error('Error in useActivityFeed:', err);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [targetUserName]);

  return { activities, loading, error };
}; 