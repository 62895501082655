import { useState, useCallback } from 'react';
import * as pokemonSetApi from '../api/pokemonSetApi';
import type {
    PokemonSet,
    PokemonCard,
    UploadCardImageResponse,
    DeleteCardImageResponse,
} from '../api/pokemonSetApi';
import { toast } from 'react-toastify';
import config from '../routes/config';
import { useAuth } from '../context/AuthContext';

export const usePokemonSetFunctions = () => {
    const [loading, setLoading] = useState(false);
    const { authParams } = useAuth();

    const getPokemonSets = useCallback(async (): Promise<PokemonSet[]> => {
        setLoading(true);
        try {
            const result = await pokemonSetApi.getPokemonSets();
            if (result.success && result.pokemonSets) {
                return result.pokemonSets;
            }
            return [];
        } finally {
            setLoading(false);
        }
    }, []);

    const getPokemonSetCards = useCallback(async (pokemonSetId: number): Promise<PokemonCard[]> => {
        setLoading(true);
        try {
            const result = await pokemonSetApi.getPokemonSetCards(pokemonSetId);
            if (result.success && result.pokemonSetCards) {
                return result.pokemonSetCards;
            }
            return [];
        } finally {
            setLoading(false);
        }
    }, []);

    const uploadCardImage = useCallback(
        async (
            file: File,
            cardId: number,
            image_folder: string,
            filename: string,
            userName: string,
            adminKey: string
        ): Promise<UploadCardImageResponse> => {
            setLoading(true);
            try {
                const result = await pokemonSetApi.uploadCardImage(
                    file,
                    cardId,
                    image_folder,
                    filename,
                    userName,
                    adminKey
                );
                return result;
            } finally {
                setLoading(false);
            }
        },
        []
    );

    const deleteCardImage = useCallback(
        async (
            cardId: number,
            imageType: 'standard' | 'high',
            userName: string,
            adminKey: string
        ): Promise<DeleteCardImageResponse> => {
            setLoading(true);
            try {
                const result = await pokemonSetApi.deleteCardImage(
                    cardId,
                    imageType,
                    userName,
                    adminKey
                );
                return result;
            } finally {
                setLoading(false);
            }
        },
        []
    );

    const uploadSetImage = useCallback(async (
        file: File,
        setId: number,
        type: 'icon' | 'logo',
        currentPath: string | null,
        userName: string,
        adminKey: string
    ): Promise<{ success: boolean; imageUrl?: string }> => {
        try {
            const result = await pokemonSetApi.uploadSetImage(
                file,
                setId,
                type,
                currentPath,
                userName,
                adminKey
            );

            return result;
        } catch (error) {
            console.error('Error uploading set image:', error);
            toast.error(`Error uploading ${type}`);
            return { success: false };
        }
    }, []);

    const deleteSetImage = useCallback(async (
        setId: number,
        type: 'icon' | 'logo',
        userName: string,
        adminKey: string
    ): Promise<{ success: boolean }> => {
        try {
            const queryParams = new URLSearchParams({
                userName,
                adminKey,
                userUUID: authParams?.userUUID || '',
                deviceUUID: authParams?.deviceUUID || ''
            }).toString();

            console.log('Sending delete request with:', {
                url: `${config.API_URL}/admin/pokemon-sets/${setId}/delete-image/${type}?${queryParams}`,
                method: 'DELETE',
                userName,
                adminKey,
                userUUID: authParams?.userUUID,
                deviceUUID: authParams?.deviceUUID,
                setId,
                type
            });

            const response = await fetch(
                `${config.API_URL}/admin/pokemon-sets/${setId}/delete-image/${type}?${queryParams}`,
                { 
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authParams?.token || ''}`
                    }
                }
            );

            console.log('Response status:', response.status);
            const result = await response.json();
            console.log('Response data:', result);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (!result.success) {
                toast.error(result.message || `Failed to delete ${type}`);
            } else {
                toast.success(`${type} deleted successfully`);
            }
            return result;
        } catch (error) {
            console.error('Error deleting set image:', error);
            console.error('Full error details:', {
                setId,
                type,
                userName,
                adminKey,
                authParams
            });
            toast.error(`Error deleting ${type}`);
            return { success: false };
        }
    }, [authParams]);

    return {
        getPokemonSets,
        getPokemonSetCards,
        uploadCardImage,
        deleteCardImage,
        uploadSetImage,
        deleteSetImage,
        loading,
    };
};
