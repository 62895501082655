import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import colorConfigs from '../../configs/colorConfigs';
import { RouteType } from '../../routes/config';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from './SidebarItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { processSidebarIcon } from '../../utils/sidebarUtils';
import 'react-lazy-load-image-component/src/effects/blur.css';

type Props = {
    item: RouteType;
    onItemClick?: () => void;
};

const SidebarItemCollapse = ({ item, onItemClick }: Props) => {
    const [open, setOpen] = useState(true);
    const appState = useSelector((state: RootState) => state.appState.appState);

    useEffect(() => {
        if (appState.includes(item.state)) {
            setOpen(true);
        }
    }, [appState, item]);

    const handleCollapse = () => {
        setOpen(!open);
    };

    // Filter out null routes while preserving the route structure
    const validChildren = item.child?.filter((route): route is RouteType => {
        return route !== null && route.displayName !== undefined;
    }) || [];

    const childKey = validChildren.map(child => child.path).join('-') || '';

    return item.displayName ? (
        <>
            <ListItemButton
                onClick={handleCollapse}
                sx={{
                    '&: hover': {
                        backgroundColor: colorConfigs.sidebar.hoverBg,
                    },
                    paddingX: '24px',
                }}
            >
                <ListItemIcon
                    sx={{
                        color: colorConfigs.sidebar.color,
                        minWidth: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {item.icon && processSidebarIcon(item.icon)}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={<Typography>{item.displayName}</Typography>}
                />
                {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" key={childKey}>
                <List>
                    {validChildren.map((route, index) => (
                        route.child ? (
                            <SidebarItemCollapse 
                                item={route} 
                                key={`${route.path}-${index}`}
                                onItemClick={onItemClick}
                            />
                        ) : (
                            <SidebarItem 
                                item={route} 
                                key={`${route.path}-${index}`}
                                onItemClick={onItemClick}
                            />
                        )
                    ))}
                </List>
            </Collapse>
        </>
    ) : null;
};

export default SidebarItemCollapse;
