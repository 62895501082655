const assets = {
    site: {
        images: {
            logo: require('./images/Reusable Image Assets/PK.png'),
            logo2: require('./images/Reusable Image Assets/PK2.png'),

            backupImage: require('./images/Reusable Image Assets/missingCardImage.jpg'),

            pokeball: require('./images/Reusable Image Assets/pokeball.png'),
            pokeballCaught: require('./images/Reusable Image Assets/pokeball_Caught.png'),

            tcgplayerIcon1:
                'https://mypokellection.com/ImageAssets/TCGplayer-Primary-RGB_100px.png',

            Colorless: require('./images/Reusable Image Assets/Colorless.png'),
            Dark: require('./images/Reusable Image Assets/Dark.png'),
            Darkness: require('./images/Reusable Image Assets/Darkness.png'),
            Dragon: require('./images/Reusable Image Assets/Dragon.png'),
            Fairy: require('./images/Reusable Image Assets/Fairy.png'),
            Fighting: require('./images/Reusable Image Assets/Fighting.png'),
            Fire: require('./images/Reusable Image Assets/Fire.png'),
            Grass: require('./images/Reusable Image Assets/Grass.png'),
            Lightning: require('./images/Reusable Image Assets/Lightning.png'),
            Metal: require('./images/Reusable Image Assets/Metal.png'),
            Psychic: require('./images/Reusable Image Assets/Psychic.png'),
            Water: require('./images/Reusable Image Assets/Water.png'),
        },
    },

    pokedex: {
        pokemon: {
            PK123: 'https://mypokellection.com/Pokedex/123.png',
        },
    },
};

export default assets;
