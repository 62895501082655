import React, { useState, useRef } from 'react';
import { Autocomplete, TextField, CircularProgress, Typography as MuiTypography, Button } from '@mui/material';
import { usePokeDex, Pokemon } from '../../hooks/usePokeDex';
import ClearIcon from '@mui/icons-material/Clear';
import '../../css/popup.css';
import { useDispatch } from 'react-redux';
import { setFavoritePokemonId, setFavoritePokemonImage } from '../../redux/slices/authSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CountrySelector from '../selectors/CountrySelector';
import { useUserFunctions } from '../../hooks/useUserFunctions';
import styled from '@emotion/styled';

interface EditProfilePopupProps {
    open: boolean;
    onClose: () => void;
    onSave: (pokemonId: number, countryCode: string) => void;
    currentFavoritePokemon: number | null;
    currentFavoritePokemonImage: string | null;
    currentCountryCode: string;
}

const StatBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    height: '100%',
    marginBottom: '20px',
});

const StatLabel = styled(MuiTypography)({
    fontSize: '0.875rem',
    color: 'text.secondary',
    textAlign: 'center',
    marginBottom: '8px',
    variant: 'subtitle2'
});

const EditProfilePopup: React.FC<EditProfilePopupProps> = ({
    open,
    onClose,
    onSave,
    currentFavoritePokemon,
    currentFavoritePokemonImage,
    currentCountryCode,
}) => {
    const [selectedCountry, setSelectedCountry] = useState<string>(currentCountryCode);
    const [selectedPokemon, setSelectedPokemon] = useState<Pokemon | null>(null);
    const { pokedex, loading, hasMore, fetchPokedex } = usePokeDex();
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { updateFavoritePokemon, updateUserCountry } = useUserFunctions();

    const handleSave = async (event: React.MouseEvent) => {
        event.stopPropagation();
        let updatedSuccessfully = true;

        if (selectedCountry && selectedCountry !== currentCountryCode) {
            try {
                const success = await updateUserCountry(selectedCountry);
                if (!success) {
                    updatedSuccessfully = false;
                }
            } catch (error) {
                updatedSuccessfully = false;
            }
        }

        if (selectedPokemon && selectedPokemon.id !== currentFavoritePokemon) {
            try {
                const success = await updateFavoritePokemon(selectedPokemon.id);
                if (success) {
                    // Update Redux state
                    dispatch(setFavoritePokemonId(selectedPokemon.id));
                    dispatch(setFavoritePokemonImage(selectedPokemon.image));
                } else {
                    updatedSuccessfully = false;
                }
            } catch (error) {
                updatedSuccessfully = false;
            }
        }

        if (updatedSuccessfully) {
            onSave(
                selectedPokemon ? selectedPokemon.id : currentFavoritePokemon || 0,
                selectedCountry
            );
            onClose();
        }
    };

    const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - scrollTop === clientHeight && hasMore && !loading) {
            fetchPokedex();
        }
    };

    if (!open) return null;

    return (
        <div className="popup-overlay" onMouseDown={onClose}>
            <div
                ref={containerRef}
                className="popup-container"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <div className="popup-header">
                    <MuiTypography variant="h4">Edit Profile</MuiTypography>
                    <button
                        className="popup-close-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                    >
                        <ClearIcon />
                    </button>
                </div>
                <div className="popup-content">
                    <StatBox>
                        <StatLabel>Current Profile Picture</StatLabel>
                        <LazyLoadImage
                            src={currentFavoritePokemonImage || ''}
                            alt="Current Favorite Pokémon"
                            effect="blur"
                            width={100}
                            height={100}
                            style={{ borderRadius: '50%', marginBottom: '16px' }}
                        />
                    </StatBox>

                    <StatBox>
                        <StatLabel>Change Favorite Pokémon</StatLabel>
                        <MuiTypography 
                            variant="body2"
                            sx={{ 
                                color: 'text.secondary',
                                mb: 2,
                                textAlign: 'center'
                            }}
                        >
                            Select a new Pokémon to set as your profile picture.
                        </MuiTypography>
                        <Autocomplete<Pokemon>
                            id="favorite-pokemon"
                            options={pokedex}
                            groupBy={(option: Pokemon) => `Generation ${option.generation}`}
                            getOptionLabel={(option: Pokemon) => option.name}
                            renderOption={(props, option: Pokemon) => (
                                <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                                    <LazyLoadImage
                                        src={option.image}
                                        alt={option.name}
                                        width={50}
                                        height={50}
                                        effect="blur"
                                        style={{ marginRight: 15 }}
                                    />
                                    <span style={{ fontSize: '16px' }}>
                                        {option.number} - {option.name}
                                    </span>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Favorite Pokémon"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            value={selectedPokemon}
                            onChange={(event, newValue) => {
                                setSelectedPokemon(newValue);
                            }}
                            loading={loading}
                            ListboxProps={{
                                style: { maxHeight: '300px', overflowY: 'auto' },
                                onScroll: handleScroll,
                            }}
                            sx={{ width: '100%' }}
                        />
                    </StatBox>

                    <StatBox>
                        <StatLabel>Change Country</StatLabel>
                        <MuiTypography 
                            variant="body2"
                            sx={{ 
                                color: 'text.secondary',
                                mb: 2,
                                textAlign: 'center'
                            }}
                        >
                            Select your country. This will be displayed on your profile.
                        </MuiTypography>
                        <CountrySelector
                            onChange={(code) => setSelectedCountry(code)}
                            value={selectedCountry}
                            sx={{ width: '100%' }}
                        />
                    </StatBox>
                </div>

                <div className="popup-footer">
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                        sx={{ mr: 1 }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        disabled={!selectedPokemon && selectedCountry === currentCountryCode}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditProfilePopup;
