import * as React from 'react';
import { Link } from 'react-router-dom';
import { RouteType } from '../../routes/config';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    useMediaQuery,
    Stack,
    Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import assets from '../../assets';
import Avatar from '@mui/material/Avatar';
import '../../css/sideBar.css';
import { useSidebarRoutes } from '../../routes';
import '../../css/footer.css';

const Topbar = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const { authenticated, userName, favoritePokemonImage } = auth;
    const TCGState = useSelector((state: RootState) => state.TCGState);
    const authenticatedStorage = localStorage.getItem('authenticated');
    
    const routes = useSidebarRoutes();
    const [filteredRoutes, setFilteredRoutes] = React.useState<RouteType[]>([]);

    const isMobile = useMediaQuery('(max-width:788px)');
    const isLargeScreen = useMediaQuery('(min-width:789px)');
    const [openDrawer, setOpenDrawer] = React.useState(false);

    React.useEffect(() => {
        if (routes) {
            const adminKey = localStorage.getItem('adminKey');
            
            const filtered = routes.filter((route) => {
                if (route.beta) {
                    return false;
                }

                if (route.path?.startsWith('/admin')) {
                    const show = !!adminKey;
                    return show;
                }

                if (route.child) {
                    route.child = route.child.filter((childRoute) => {
                        if (childRoute.beta) return false;
                        if (childRoute.path?.startsWith('/admin')) return !!adminKey;
                        return true;
                    });
                }

                if (route.isMenu && route.child && route.child.length > 0) {
                    return true;
                }

                if (route.state === 'Login') {
                    const show = !authenticated && !authenticatedStorage;
                    return show;
                }

                if (route.state === 'Logout') {
                    const show = authenticated || authenticatedStorage === 'true';
                    return show;
                }

                const show = route.displayName;
                return show;
            });

            setFilteredRoutes(filtered);
        }
    }, [routes, authenticated, authenticatedStorage, TCGState]);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    return (
        <React.Fragment>
            {!isLargeScreen && (
                <AppBar
                    position="fixed"
                    sx={{
                        width: '100%',
                        marginLeft: 0,
                        boxShadow: 'unset',
                        backgroundColor: colorConfigs.topbar.bg,
                        color: colorConfigs.topbar.color,
                        '@media (min-width: 789px)': {
                            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                            marginLeft: sizeConfigs.sidebar.width,
                        },
                    }}
                >
                    <Toolbar>
                        <Grid container alignItems="center">
                            <Grid item xs={3} textAlign="left">
                                {/* Logo on the far left */}
                                <img
                                    style={{ width: 'auto', height: 'auto', maxWidth: '50px' }}
                                    src={assets.site.images.logo}
                                    alt="logo"
                                />
                            </Grid>
                            <Grid item xs={6} textAlign="center">
                                {/* Centered menu */}
                                {isMobile && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerOpen}
                                        sx={{
                                            backgroundColor: colorConfigs.topbar.bg,
                                            color: colorConfigs.topbar.color,
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item xs={3} textAlign="right">
                                <img
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: '100px',
                                        paddingRight: '10px',
                                    }}
                                    src={assets.site.images.logo2}
                                    alt="logo"
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            )}

            <Drawer
                variant="temporary"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        boxSizing: 'border-box',
                        borderRight: '0px',
                        backgroundColor: colorConfigs.sidebar.bg,
                        color: colorConfigs.sidebar.color,
                    },
                }}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerClose}
                    sx={{
                        marginRight: 2,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <List disablePadding>
                    <Toolbar sx={{ marginBottom: '20px' }}>
                        <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
                            <Link to="/">
                                <img
                                    style={{ width: 'auto', height: 'auto', maxWidth: '100px' }}
                                    src={assets.site.images.logo}
                                    alt=""
                                />
                            </Link>
                        </Stack>
                    </Toolbar>
                    <div className="sidebar-userProfile">
                        {authenticated ? (
                            <>
                                <Link to={`/u/${userName ?? 'Guest'}`} onClick={handleDrawerClose}>
                                    <Avatar
                                        alt={userName ?? 'Guest'}
                                        src={favoritePokemonImage || assets.pokedex.pokemon.PK123}
                                        key={favoritePokemonImage}
                                    />
                                </Link>
                                <Link to={`/u/${userName ?? 'Guest'}`} onClick={handleDrawerClose}>
                                    <p>{userName ?? 'Guest'}</p>
                                </Link>
                            </>
                        ) : (
                            <>
                                <Avatar alt="Guest" src={assets.pokedex.pokemon.PK123} />
                                <p>Guest</p>
                            </>
                        )}
                    </div>
                    {filteredRoutes.map((route, index) =>
                        route.displayName ? (
                            route.child ? (
                                <SidebarItemCollapse 
                                    item={route} 
                                    key={`${route.path}-${index}`}
                                    onItemClick={() => setOpenDrawer(false)}
                                />
                            ) : (
                                <SidebarItem 
                                    item={route} 
                                    key={`${route.path}-${index}`}
                                    onItemClick={() => setOpenDrawer(false)}
                                />
                            )
                        ) : null
                    )}
                </List>
                <div className="footer-container">
                    {/* <Link className="privacy-policy-link" to="/privacy-policy" onClick={handleDrawerClose}>
                        Privacy Policy
                    </Link> */}
                    <p className="footer-text">
                        MyPokellection is not affiliated with, sponsored or endorsed by, or in any way 
                        associated with Pokemon or The Pokemon Company International Inc
                    </p>
                </div>
            </Drawer>
        </React.Fragment>
    );
};

export default Topbar;
