import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PokemonSetManagerHeader from './PokemonSetManagerHeader';
import PokemonSetManagerTable from './PokemonSetManagerTable';
import { PokemonSet } from '../../../../types/admin';
import './PokemonSetManager.css';

const PokemonSetManager: React.FC = () => {
    const [sets, setSets] = useState<PokemonSet[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const { getPokemonSets, updatePokemonSet } = useAdminFunctions();

    useEffect(() => {
        const fetchSets = async () => {
            try {
                setIsLoading(true);
                const fetchedSets = await getPokemonSets();
                setSets(fetchedSets);
            } catch (error) {
                console.error('Error fetching sets:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSets();
    }, [getPokemonSets]);

    const handleSetUpdate = async (updatedSet: PokemonSet) => {
        try {
            const success = await updatePokemonSet(updatedSet);
            if (success) {
                setSets(sets.map(set => 
                    set.id === updatedSet.id ? updatedSet : set
                ));
            }
        } catch (error) {
            console.error('Error updating set:', error);
        }
    };

    const filteredSets = useMemo(() => {
        if (!searchTerm) return sets;
        
        const lowerSearchTerm = searchTerm.toLowerCase();
        return sets.filter(set => 
            set.name.toLowerCase().includes(lowerSearchTerm) ||
            set.type.toLowerCase().includes(lowerSearchTerm) ||
            String(set.seriesId).includes(lowerSearchTerm) ||
            String(set.setNumber).includes(lowerSearchTerm)
        );
    }, [sets, searchTerm]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Box sx={{ p: 2 }}>
            <PokemonSetManagerHeader 
                totalSets={sets.length} 
                searchTerm={searchTerm}
                onSearchChange={setSearchTerm}
            />
            <PokemonSetManagerTable 
                sets={filteredSets}
                onSetUpdate={handleSetUpdate}
            />
        </Box>
    );
};

export default PokemonSetManager; 