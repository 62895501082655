import React from 'react';
import { Paper, Stack, Typography, Box, Button } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import assets from '../../../src/assets';

interface KnownCard {
    name: string;
    image: string;
}

interface ComingSoonSetInfo {
    logo?: string;
    setName: string;
    setSeries: string;
    releaseDate?: string;
    description?: string;
    tcgplayerlink?: string;
    summary?: string;
    knownCards?: KnownCard[];
}

interface ComingSoonTemplateProps {
    setInfo?: ComingSoonSetInfo;
}

const ComingSoonTemplate: React.FC<ComingSoonTemplateProps> = ({ setInfo }) => {
    if (!setInfo) {
        return (
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={3} sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
                <Paper elevation={4} sx={{ p: 2, width: '100%', textAlign: 'center' }}>
                    <Typography variant="h6">Set information not available</Typography>
                </Paper>
            </Stack>
        );
    }

    const defaultLink = 'https://partner.tcgplayer.com/c/5054575/1780961/21018?u=https://www.tcgplayer.com/categories/trading-and-collectible-card-games/pokemon';
    const dynamicLink = setInfo.tcgplayerlink ? `https://partner.tcgplayer.com/c/5054575/1780961/21018?u=${encodeURIComponent(setInfo.tcgplayerlink)}` : defaultLink;

    return (
        <Box
            sx={{
                position: 'relative',
                minHeight: '100%',
                width: '100%',
                backgroundColor: 'background.default',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    inset: '-50%',
                    backgroundImage: setInfo.logo ? `url("${setInfo.logo}")` : 'none',
                    backgroundPosition: 'center',
                    backgroundSize: '200%',
                    opacity: 0.06,
                    filter: 'blur(15px)',
                    zIndex: 0,
                    pointerEvents: 'none'
                }
            }}
        >
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={4} 
                sx={{ 
                    maxWidth: '1200px', 
                    margin: '0 auto', 
                    padding: '20px',
                    position: 'relative',
                    zIndex: 1
                }}
            >
                {/* Logo Section */}
                {setInfo.logo && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <LazyLoadImage
                            className="image"
                            src={setInfo.logo}
                            alt={`${setInfo.setName} Logo`}
                            effect="blur"
                            style={{
                                width: 'auto',
                                height: 'auto',
                                maxWidth: '400px',
                                maxHeight: '300px',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                )}

                {/* Title Section */}
                <Typography 
                    variant="h3" 
                    component="h1" 
                    align="center" 
                    sx={{ 
                        fontWeight: 'bold',
                        color: 'primary.main',
                        mb: 2
                    }}
                >
                    {setInfo.setName} is coming {setInfo.releaseDate ? `on ${setInfo.releaseDate}` : 'soon'}
                </Typography>

                {/* Summary Section */}
                {setInfo.summary && (
                    <Paper elevation={4} sx={{ p: 3, width: '100%', bgcolor: 'background.paper' }}>
                        <Typography 
                            variant="h6" 
                            component="div" 
                            align="center"
                            sx={{ 
                                mb: 2,
                                lineHeight: 1.6,
                                color: 'text.primary'
                            }}
                        >
                            {setInfo.summary}
                        </Typography>
                    </Paper>
                )}

                {/* Pre-order Button */}
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        href={dynamicLink}
                        target="_blank"
                        rel="noreferrer"
                        sx={{
                            py: 2,
                            px: 4,
                            borderRadius: 2,
                            fontSize: '1.2rem',
                            textTransform: 'none'
                        }}
                    >
                        Pre-order on TCGplayer
                    </Button>
                    <img
                        src={assets.site.images.tcgplayerIcon1}
                        alt="TCGplayer"
                        width="100"
                        height="39"
                    />
                    {/* Tracking pixel */}
                    <img
                        height="0"
                        width="0"
                        src="https://partner.tcgplayer.com/Vx4RaM"
                        alt=""
                        style={{ position: 'absolute', visibility: 'hidden' }}
                    />
                </Box>

                {/* Known Cards Section */}
                {setInfo.knownCards && setInfo.knownCards.length > 0 && (
                    <Paper elevation={4} sx={{ p: 3, width: '100%', mt: 4 }}>
                        <Typography variant="h4" fontWeight="bold" gutterBottom align="center">
                            Known Cards
                        </Typography>
                        <Stack direction="row" spacing={2} flexWrap="wrap" justifyContent="center">
                            {setInfo.knownCards.map((card: KnownCard, index: number) => (
                                <Box key={index} sx={{ width: 200, textAlign: 'center', m: 1 }}>
                                    <LazyLoadImage
                                        src={card.image}
                                        alt={card.name}
                                        effect="blur"
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '10px'
                                        }}
                                    />
                                    <Typography variant="body1" sx={{ mt: 1, fontWeight: 'medium' }}>
                                        {card.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Stack>
                    </Paper>
                )}
            </Stack>
        </Box>
    );
};

export default ComingSoonTemplate;
