import { ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import colorConfigs from '../../configs/colorConfigs';
import { RootState } from '../../redux/store';
import { RouteType } from '../../routes/config';
import { processSidebarIcon } from '../../utils/sidebarUtils';
import 'react-lazy-load-image-component/src/effects/blur.css';

type Props = {
    item: RouteType;
    onItemClick?: () => void;
};

const SidebarItem = ({ item, onItemClick }: Props) => {
    const appState = useSelector((state: RootState) => state.appState.appState);

    const handleClick = () => {
        if (onItemClick) {
            onItemClick();
        }
    };

    return item.displayName && item.path ? (
        <ListItemButton
            component={Link}
            to={item.path}
            onClick={handleClick}
            sx={{
                '&:hover': {
                    backgroundColor: colorConfigs.sidebar.hoverBg,
                },
                backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : 'unset',
                paddingX: '24px',
            }}
        >
            <ListItemIcon
                sx={{
                    color: colorConfigs.sidebar.color,
                }}
            >
                {item.icon && processSidebarIcon(item.icon)}
            </ListItemIcon>

            <Typography
                sx={{
                    color: appState === item.state ? '#fff400' : 'inherit',
                }}
            >
                {item.displayName}
            </Typography>
        </ListItemButton>
    ) : null;
};

export default SidebarItem;
