import { useState, useEffect, useCallback } from 'react';
import * as userApi from '../api/userApi';
import { SeriesSummary } from '../types/collection';

export const useCollectionSummary = (userName: string) => {
    const [summary, setSummary] = useState<SeriesSummary[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchSummary = useCallback(async () => {
        if (!userName) {
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await userApi.getUserCollectionSummary(userName);
            
            if (response.success && response.summary) {
                setSummary(response.summary.series);
            } else {
                setError(response.message || 'Failed to fetch collection summary');
            }
        } catch (err) {
            setError('An error occurred while fetching collection summary');
        } finally {
            setLoading(false);
        }
    }, [userName]);

    useEffect(() => {
        fetchSummary();
    }, [fetchSummary]);

    return { summary, loading, error, refetch: fetchSummary };
}; 