import React, { memo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { PokemonSet } from '../../../../types/admin';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { usePokemonSetFunctions } from '../../../../hooks/usePokemonSetFunctions';
import { useAuth } from '../../../../context/AuthContext';
import assets from '../../../../assets';

const UploadBox = styled(Box)(({ theme }) => ({
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

interface PokemonSetEditDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    values: Partial<PokemonSet>;
    onChange: (field: keyof PokemonSet, value: any) => void;
}

const PokemonSetEditDialog = memo(({ 
    open, 
    onClose, 
    onSave, 
    values, 
    onChange 
}: PokemonSetEditDialogProps) => {
    const { uploadSetImage, deleteSetImage } = usePokemonSetFunctions();
    const { authParams } = useAuth();

    const handleFileSelect = async (
        event: React.ChangeEvent<HTMLInputElement>,
        type: 'icon' | 'logo'
    ) => {
        const file = event.target.files?.[0];
        if (!file || !authParams || !values.id) return;

        const locationField = type === 'icon' ? 'iconLocation' : 'logoLocation';
        const currentPath = values[locationField];
        
        const result = await uploadSetImage(
            file,
            values.id,
            type,
            currentPath || null,
            authParams.userName,
            authParams.adminKey
        );

        if (result.success && result.imageUrl) {
            onChange(locationField as keyof PokemonSet, result.imageUrl);
        }
    };

    const handleDeleteImage = async (type: 'icon' | 'logo') => {
        if (!authParams || !values.id) return;

        const result = await deleteSetImage(
            values.id,
            type,
            authParams.userName,
            authParams.adminKey
        );

        if (result.success) {
            const locationField = type === 'icon' ? 'iconLocation' : 'logoLocation';
            onChange(locationField as keyof PokemonSet, '');
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="md" 
            fullWidth
        >
            <DialogTitle>Edit Pokemon Set</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                        <TextField
                            label="Name"
                            value={values.name || ''}
                            onChange={(e) => onChange('name', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Series ID"
                            type="number"
                            value={values.seriesId || ''}
                            onChange={(e) => onChange('seriesId', Number(e.target.value))}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Language ID"
                            type="number"
                            value={values.languageId || ''}
                            onChange={(e) => onChange('languageId', Number(e.target.value))}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Number of Cards"
                            type="number"
                            value={values.numberOfCards || ''}
                            onChange={(e) => onChange('numberOfCards', Number(e.target.value))}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Type"
                            value={values.type || ''}
                            onChange={(e) => onChange('type', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Release Date"
                            value={values.releaseDate || ''}
                            onChange={(e) => onChange('releaseDate', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Set Number"
                            type="number"
                            value={values.setNumber || ''}
                            onChange={(e) => onChange('setNumber', Number(e.target.value))}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!values.includesReverseHolographicCards}
                                            onChange={(e) => onChange('includesReverseHolographicCards', e.target.checked)}
                                        />
                                    }
                                    label="Includes Reverse Holo"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!values.show_price_data}
                                            onChange={(e) => onChange('show_price_data', e.target.checked)}
                                        />
                                    }
                                    label="Show Price Data"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!values.has_print_runs}
                                            onChange={(e) => onChange('has_print_runs', e.target.checked)}
                                        />
                                    }
                                    label="Has Print Runs"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Image Folder"
                            value={values.image_folder || ''}
                            onChange={(e) => onChange('image_folder', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="TCGPlayer Link"
                            value={values.tcgplayerlink || ''}
                            onChange={(e) => onChange('tcgplayerlink', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Set Icon
                        </Typography>
                        {values.iconLocation ? (
                            <Box position="relative">
                                <LazyLoadImage
                                    src={values.iconLocation}
                                    alt="Set Icon"
                                    effect="blur"
                                    width="100%"
                                    height="auto"
                                    style={{ maxHeight: '200px', objectFit: 'contain' }}
                                />
                                <IconButton
                                    onClick={() => handleDeleteImage('icon')}
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <label htmlFor="icon-upload">
                                <UploadBox>
                                    <input
                                        type="file"
                                        id="icon-upload"
                                        hidden
                                        accept="image/*"
                                        onChange={(e) => handleFileSelect(e, 'icon')}
                                    />
                                    <CloudUploadIcon sx={{ fontSize: 40, mb: 1 }} />
                                    <Typography>Upload Set Icon</Typography>
                                </UploadBox>
                            </label>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Set Logo
                        </Typography>
                        {values.logoLocation ? (
                            <Box position="relative">
                                <LazyLoadImage
                                    src={values.logoLocation}
                                    alt="Set Logo"
                                    effect="blur"
                                    width="100%"
                                    height="auto"
                                    style={{ maxHeight: '200px', objectFit: 'contain' }}
                                />
                                <IconButton
                                    onClick={() => handleDeleteImage('logo')}
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <label htmlFor="logo-upload">
                                <UploadBox>
                                    <input
                                        type="file"
                                        id="logo-upload"
                                        hidden
                                        accept="image/*"
                                        onChange={(e) => handleFileSelect(e, 'logo')}
                                    />
                                    <CloudUploadIcon sx={{ fontSize: 40, mb: 1 }} />
                                    <Typography>Upload Set Logo</Typography>
                                </UploadBox>
                            </label>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!values.isBeta}
                                    onChange={(e) => onChange('isBeta', e.target.checked)}
                                />
                            }
                            label="Is Beta"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSave} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
});

PokemonSetEditDialog.displayName = 'PokemonSetEditDialog';

export default PokemonSetEditDialog; 