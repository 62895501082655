import React, { useRef, useEffect, useState } from 'react';
import { Box, IconButton, Typography, Divider, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import assets from '../../../assets';
import config from '../../../routes/config';

// Styled components
const StyledOverlay = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
    padding: '20px',
});

const StyledContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    maxWidth: '1200px',
    width: '100%',
    maxHeight: '90vh',
    overflow: 'auto',
    position: 'relative',
    boxShadow: theme.shadows[24],
}));

const StyledHeader = styled(Box)(({ theme }) => ({
    padding: '16px 24px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
}));

const StyledContent = styled(Box)(({ theme }) => ({
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },
}));

const InfoSection = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
}));

const InfoCard = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    borderRadius: '8px',
    padding: '16px',
}));

const ImageSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
    },
}));

type Props = {
    cardInfo: {
        id: number;
        cardsetindex: number;
        editionId?: number;
        imageLocation2x: string;
        imagelocation: string;
        isCardInCollection: boolean;
        isErrorCard: number;
        isPartOfSubSet: number;
        isPromoCard: number;
        isReverseHolo: number;
        languageId: number;
        market_price: number;
        number: string;
        pokemonsetId: number;
        rarityId?: number;
        seriesId?: number;
        showPriceData?: boolean;
        name: string;
        seriesName: string;
        setName: string;
        product_id: number;
        tcgPlayerProductLink: string;
        setSeries: string;
        icon?: string;
        type: string;
        setPrintRuns?: {
            firstEditionSet?: {
                buttonDisplay: string;
                description: string;
            };
            shadowlessSet?: {
                buttonDisplay: string;
                description: string;
            };
            unlimitedEditionSet?: {
                buttonDisplay: string;
                description: string;
            };
            fourthPrintSet?: {
                buttonDisplay: string;
                description: string;
            };
        };
    };
    onClose: () => void;
};

const CardTemplate: React.FC<Props> = ({ cardInfo, onClose }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState({
        edition: null,
        rarity: null,
        hp: null,
        weakness: null,
        resistance: null,
        retreatCost: null,
        type: null,
        typeIcon: null,
        stage: null,
        attacks: [
            {
                attackOriginalString: null,
                attackName: null,
                attackDescription: null,
                attackDamage: null,
                attackEnergyCost: null,
            },
            {
                attackOriginalString: null,
                attackName: null,
                attackDescription: null,
                attackDamage: null,
                attackEnergyCost: null,
            },
        ],
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    useEffect(() => {
        const fetchCardDetails = async () => {
            if (cardInfo.id) {
                setLoading(true);
                try {
                    const response = await fetch(`${config.API_URL}/getCardDetails/${cardInfo.id}`, {
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        },
                        method: 'GET'
                    });
                    const cardDetails = await response.json();

                    setData({
                        edition: cardDetails.editionName,
                        rarity: cardDetails.cardRarityName,
                        hp: cardDetails.cardHP,
                        weakness: cardDetails.cardWeakness,
                        resistance: cardDetails.cardResistance,
                        retreatCost: cardDetails.cardRetreatCost,
                        type: cardDetails.cardType,
                        typeIcon: cardDetails.cardIcon,
                        stage: cardDetails.cardStageName,
                        attacks: (cardDetails.attacks || []).slice(0, 2),
                    });
                } catch (error) {
                    console.error('Error fetching card details:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchCardDetails();
    }, [cardInfo.id]);

    const originalLink = 'https://partner.tcgplayer.com/c/5054575/1780961/21018';
    let modifiedLink = '';

    if (cardInfo.type === 'WOTC' && cardInfo?.setPrintRuns?.firstEditionSet) {
        if (cardInfo.editionId === 1) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=1st+Edition+Holofoil|1st+Edition`;
        } else if (cardInfo.editionId === 3) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=Unlimited+Holofoil|Unlimited`;
        }
    } else {
        if (cardInfo.isReverseHolo) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=Reverse+Holofoil`;
        } else if (cardInfo.isPartOfSubSet) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=Holofoil`;
        } else {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${cardInfo.product_id}?Printing=Normal|Holofoil`;
        }
    }

    return (
        <StyledOverlay>
            <StyledContainer ref={containerRef}>
                <StyledHeader>
                    <Typography variant="h6">{cardInfo.name}</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </StyledHeader>

                <StyledContent>
                    <ImageSection>
                        <LazyLoadImage
                            src={cardInfo.imageLocation2x || cardInfo.imagelocation}
                            alt={cardInfo.name}
                            effect="blur"
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px',
                            }}
                        />
                    </ImageSection>

                    <InfoSection>
                        {loading ? (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                minHeight: '400px'
                            }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <InfoCard>
                                    <Typography variant="subtitle1" gutterBottom>Set Information</Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Set</Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <img
                                                    src={cardInfo.icon}
                                                    alt={cardInfo.setName}
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                                <Typography>{cardInfo.setName}</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Series</Typography>
                                            <Typography>{cardInfo.setSeries}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Card Number</Typography>
                                            <Typography>{cardInfo.number}</Typography>
                                        </Box>
                                    </Box>
                                </InfoCard>

                                <InfoCard>
                                    <Typography variant="subtitle1" gutterBottom>Market Information</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Market Price</Typography>
                                            <Typography variant="h6">
                                                ${cardInfo.market_price?.toFixed(2) || 'N/A'}
                                            </Typography>
                                        </Box>
                                        <a href={modifiedLink} target="_blank" rel="noreferrer" id="1808389">
                                            <img
                                                src={assets.site.images.tcgplayerIcon1}
                                                alt="Buy on TCGPlayer"
                                                style={{ height: '39px' }}
                                            />
                                        </a>
                                    </Box>
                                </InfoCard>

                                <InfoCard>
                                    <Typography variant="subtitle1" gutterBottom>Card Details</Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
                                        {cardInfo.type === 'WOTC' && (
                                            <Box>
                                                <Typography variant="body2" color="text.secondary">Edition</Typography>
                                                <Typography>{data.edition || 'N/A'}</Typography>
                                            </Box>
                                        )}
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Rarity</Typography>
                                            <Typography>{data.rarity || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Type</Typography>
                                            <Typography>{data.type || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Stage</Typography>
                                            <Typography>{data.stage || 'N/A'}</Typography>
                                        </Box>
                                    </Box>
                                </InfoCard>

                                <InfoCard>
                                    <Typography variant="subtitle1" gutterBottom>Pokemon Stats</Typography>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">HP</Typography>
                                            <Typography>{data.hp || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Weakness</Typography>
                                            <Typography>{data.weakness || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Resistance</Typography>
                                            <Typography>{data.resistance || 'N/A'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body2" color="text.secondary">Retreat Cost</Typography>
                                            <Typography>{data.retreatCost || 'N/A'}</Typography>
                                        </Box>
                                    </Box>
                                </InfoCard>

                                {data.attacks.map((attack, index) => (
                                    attack && (
                                        <InfoCard key={index}>
                                            <Typography variant="subtitle1" gutterBottom>Attack {index + 1}</Typography>
                                            {attack.attackOriginalString ? (
                                                <Typography>{attack.attackOriginalString}</Typography>
                                            ) : (
                                                <>
                                                    <Box sx={{ 
                                                        display: 'grid', 
                                                        gridTemplateColumns: 'repeat(3, 1fr)', 
                                                        gap: 2,
                                                        mb: 2 
                                                    }}>
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">Energy Cost</Typography>
                                                            <Typography>{attack.attackEnergyCost || 'N/A'}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">Name</Typography>
                                                            <Typography>{attack.attackName || 'N/A'}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">Damage</Typography>
                                                            <Typography>{attack.attackDamage || 'N/A'}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body2" color="text.secondary">Description</Typography>
                                                        <Typography>{attack.attackDescription || 'N/A'}</Typography>
                                                    </Box>
                                                </>
                                            )}
                                        </InfoCard>
                                    )
                                ))}
                            </>
                        )}
                    </InfoSection>
                </StyledContent>
            </StyledContainer>
        </StyledOverlay>
    );
};

export default CardTemplate;
