import React, { useState, useMemo, useEffect } from 'react';
import { Grid, useMediaQuery, Paper, Fab, useScrollTrigger, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DesktopPokedexHeader from './DesktopPokedexHeader';
import MobilePokedexHeader from './MobilePokedexHeader';
import DesktopFavoritePokemon from './DesktopFavoritePokemon';
import MobileFavoritePokemon from './MobileFavoritePokemon';
import DesktopPokedexFilter from './DesktopPokedexFilter';
import DesktopPokemonCard from './DesktopPokemonCard';
import MobilePokemonCard from './MobilePokemonCard';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useUserFunctions } from '../../hooks/useUserFunctions';
import { RootState } from '../../redux/store'; // Make sure this path is correct
import { useCardFunctions } from '../../hooks/useCardFunctions';
import { usePokeDex, Pokemon } from '../../hooks/usePokeDex';
import LoadingSpinner from '../../components/LoadingSpinner';

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

const MyPokedex: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:789px)');
    const [selectedGen, setSelectedGen] = useState<number>(1);
    const [filterExpanded, setFilterExpanded] = useState(true);

    const userName = useSelector((state: RootState) => state.auth.userName);
    const favoritePokemonId = useSelector((state: RootState) => state.auth.favoritePokemonId);
    const isAuthenticated = useSelector((state: RootState) => state.auth.authenticated);
    const [favoritePokemon, setFavoritePokemon] = useState(null);

    const { getFavoritePokemon } = useUserFunctions();

    const {
        featuredPokemonCards,
        cameoPokemonCards,
        caughtPokemon,
        caughtPokemonIds: cardFunctionsCaughtIds,
        featuredCaughtPokemonCards,
        cameoCaughtPokemonCards,
        pokedexValue,
        cardLoading,
        favoritePokemonCardCount,
        favoritePokemonFeaturedCardCount,
        favoritePokemonCameoCardCount,
        collectedFavoritePokemonCardCount,
        collectedFavoritePokemonFeaturedCardCount,
        collectedFavoritePokemonCameoCardCount,
    } = useCardFunctions(
        userName || 'Guest',
        isAuthenticated,
        favoritePokemonId ? Number(favoritePokemonId) : null
    );

    useEffect(() => {
        const fetchFavoritePokemon = async () => {
            if (isAuthenticated && favoritePokemonId) {
                try {
                    const favorite = await getFavoritePokemon(Number(favoritePokemonId));
                    setFavoritePokemon(favorite as any);
                } catch (error) {
                    // console.error('Error fetching favorite Pokemon:', error);
                }
            }
        };
        fetchFavoritePokemon();
    }, [isAuthenticated, favoritePokemonId, getFavoritePokemon]);

    const { pokedex = [], loading = false } = usePokeDex() || {};

    const filteredPokemon = useMemo(() => {
        return (pokedex || []).filter((pokemon) => pokemon.generation === selectedGen);
    }, [selectedGen, pokedex]);

    const handleGenChange = (event: React.MouseEvent<HTMLElement>, newGen: number | null) => {
        if (newGen !== null) {
            setSelectedGen(newGen);
        }
    };

    const toggleFilterExpanded = () => {
        setFilterExpanded(!filterExpanded);
    };

    const headerData = useMemo(
        () => ({
            userName: userName || 'Guest',
            totalPokemon: 1025,
            caughtPokemon,
            caughtFeaturedCards: featuredCaughtPokemonCards,
            caughtCameoCards: cameoCaughtPokemonCards,
            totalFeaturedCards: featuredPokemonCards,
            totalCameoCards: cameoPokemonCards,
            totalMarketValue: pokedexValue,
            cardLoading,
            isAuthenticated,
        }),
        [
            userName,
            caughtPokemon,
            featuredCaughtPokemonCards,
            cameoCaughtPokemonCards,
            featuredPokemonCards,
            cameoPokemonCards,
            pokedexValue,
            cardLoading,
            isAuthenticated,
        ]
    );

    const defaultFavoritePokemon = useMemo(
        () => ({
            name: 'Unknown',
            image: '',
            number: 0,
            generation: 0,
            height: 0,
            weight: 0,
            number_featured_cards: 0,
            number_cameo_cards: 0,
        }),
        []
    );

    const favoritePokemonData = useMemo(
        () => ({
            favoritePokemon: favoritePokemon || defaultFavoritePokemon,
            favoritePokemonCardCount,
            favoritePokemonFeaturedCardCount,
            favoritePokemonCameoCardCount,
            collectedFavoritePokemonCardCount,
            collectedFavoritePokemonFeaturedCardCount,
            collectedFavoritePokemonCameoCardCount,
        }),
        [
            favoritePokemon,
            favoritePokemonCardCount,
            favoritePokemonFeaturedCardCount,
            favoritePokemonCameoCardCount,
            collectedFavoritePokemonCardCount,
            collectedFavoritePokemonFeaturedCardCount,
            collectedFavoritePokemonCameoCardCount,
            defaultFavoritePokemon,
        ]
    );

    const PokedexHeader = isMobile ? MobilePokedexHeader : DesktopPokedexHeader;
    const FavoritePokemon = isMobile ? MobileFavoritePokemon : DesktopFavoritePokemon;
    const PokemonCard = isMobile ? MobilePokemonCard : DesktopPokemonCard;

    const handlePokemonClick = (pokemon: Pokemon) => {
        navigate(`/MyPokedex/${pokemon.id}`, { state: { pokemon } });
    };

    // If either loading state is true, show loading indicator
    if (loading || cardLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div style={{ marginBottom: '5px' }}>
                <PokedexHeader {...headerData} />
            </div>
            {isAuthenticated && (
                <div style={{ marginBottom: '5px' }}>
                    <Paper>
                        <Grid item xs={12} sm={4}>
                            <FavoritePokemon {...favoritePokemonData} />
                        </Grid>
                    </Paper>
                </div>
            )}
            <div style={{ marginBottom: '5px' }}>
                <DesktopPokedexFilter
                    selectedGen={selectedGen}
                    handleGenChange={handleGenChange}
                    totalFilteredPokemon={filteredPokemon.length}
                    expanded={filterExpanded}
                    toggleExpanded={toggleFilterExpanded}
                />
            </div>
            <div style={{ marginBottom: '5px' }}>
                <Grid container spacing={2}>
                    {!loading &&
                        filteredPokemon &&
                        filteredPokemon.map((pokemon) => (
                            <Grid item xs={6} sm={4} md={3} lg={2} key={pokemon.id}>
                                <div onClick={() => handlePokemonClick(pokemon)}>
                                    <PokemonCard
                                        pokemon={pokemon as Pokemon}
                                        hasCaught={(cardFunctionsCaughtIds || []).includes(
                                            pokemon.id
                                        )}
                                    />
                                </div>
                            </Grid>
                        ))}
                </Grid>
            </div>
            <ScrollTop />
        </>
    );
};

export default MyPokedex;
