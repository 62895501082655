import React, { useState } from 'react';
import {
    Paper,
    Box,
    Typography,
    CircularProgress,
    Divider,
    IconButton,
    Collapse,
} from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number; color?: string }
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                    color: (theme) => theme.palette.grey[200],
                    position: 'absolute',
                    left: 0,
                }}
                size={props.size}
            />
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{ color: props.color || 'primary.main' }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

interface ProgressDisplayProps {
    showFirstEdition: boolean;
    showShadowlessSet: boolean;
    showfourthPrintSet: boolean;
    showReverseSet: boolean;
    showSubSet: boolean;
    type: string;
    collectedFirstEditionSet: number;
    firstEditionSet: number;
    collectedShadowlessSet: number;
    shadowlessSet: number;
    collectedUnlimitedSet: number;
    unlimitedSet: number;
    collectedMainSet: number;
    mainSet: number;
    setNumber: number;
    mainSetWithSecretRares: number;
    collectedFourthPrint: number;
    fourthPrintSet: number;
    collectedReverseHoloSet: number;
    reverseSet: number;
    collectedSubSet: number;
    subSet: number;
    collectedCards: number;
    totalSet: number;
    numberOfCards: number;
    normaliseFirstEditionSet: (value: number) => number;
    normaliseShadowlessSet: (value: number) => number;
    normaliseUnlimitedSet: (value: number) => number;
    normaliseMainSet: (value: number) => number;
    normaliseFourthPrint: (value: number) => number;
    normaliseReverseSet: (value: number) => number;
    normaliseSubSet: (value: number) => number;
    normaliseTotal: (value: number) => number;
    currentView: string;
}

const DesktopProgressDisplay: React.FC<ProgressDisplayProps> = ({
    showFirstEdition,
    showShadowlessSet,
    showfourthPrintSet,
    showReverseSet,
    showSubSet,
    type,
    collectedFirstEditionSet,
    firstEditionSet,
    collectedShadowlessSet,
    shadowlessSet,
    collectedUnlimitedSet,
    unlimitedSet,
    collectedMainSet,
    mainSet,
    setNumber,
    mainSetWithSecretRares,
    collectedFourthPrint,
    fourthPrintSet,
    collectedReverseHoloSet,
    reverseSet,
    collectedSubSet,
    subSet,
    collectedCards,
    totalSet,
    numberOfCards,
    normaliseFirstEditionSet,
    normaliseShadowlessSet,
    normaliseUnlimitedSet,
    normaliseMainSet,
    normaliseFourthPrint,
    normaliseReverseSet,
    normaliseSubSet,
    normaliseTotal,
    currentView,
}) => {
    const [expanded, setExpanded] = useState(true);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    // Update the current set totals calculation
    const getCurrentSetTotals = () => {
        switch (currentView) {
            case 'First Edition':
                return { total: firstEditionSet, collected: collectedFirstEditionSet };
            case 'Shadowless':
                return { total: shadowlessSet, collected: collectedShadowlessSet };
            case 'Unlimited':
                return type === 'WOTC'
                    ? { total: unlimitedSet, collected: collectedUnlimitedSet }
                    : { total: mainSetWithSecretRares, collected: collectedMainSet };
            case '4th Print':
                return { total: fourthPrintSet, collected: collectedFourthPrint };
            case 'Reverse Set':
                return { total: reverseSet, collected: collectedReverseHoloSet };
            case 'Sub-Set':
                return { total: subSet, collected: collectedSubSet };
            case 'All Cards':
                return { total: numberOfCards, collected: collectedCards };
            default:
                return type === 'WOTC'
                    ? { total: unlimitedSet, collected: collectedUnlimitedSet }
                    : { total: mainSetWithSecretRares, collected: collectedMainSet };
        }
    };

    const { total: currentSetTotal, collected: currentSetCollected } = getCurrentSetTotals();

    return (
        <Paper elevation={4} style={{ padding: '16px', width: '100%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography variant="h6" mr={2}>
                        Collection Progress
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        {currentSetCollected}/{currentSetTotal}
                    </Typography>
                </Box>
                <IconButton onClick={toggleExpanded} size="small">
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Box display="flex" flexWrap="wrap" flex={1}>
                        {showFirstEdition && (
                            <Box
                                key="firstEdition"
                                m={1}
                                textAlign="center"
                                sx={{ color: '#0288d1' }}
                            >
                                <CircularProgressWithLabel
                                    value={normaliseFirstEditionSet(collectedFirstEditionSet)}
                                    size={80}
                                    color="inherit" // Blue
                                />
                                <Typography variant="body2">
                                    First Edition
                                    <br />
                                    {collectedFirstEditionSet} / {firstEditionSet}
                                </Typography>
                            </Box>
                        )}

                        {showShadowlessSet && (
                            <Box
                                key="shadowlessSet"
                                m={1}
                                textAlign="center"
                                sx={{ color: '#9c27b0' }}
                            >
                                <CircularProgressWithLabel
                                    value={normaliseShadowlessSet(collectedShadowlessSet)}
                                    size={80}
                                    color="inherit" // Purple
                                />
                                <Typography variant="body2">
                                    Shadowless
                                    <br />
                                    {collectedShadowlessSet} / {shadowlessSet}
                                </Typography>
                            </Box>
                        )}

                        {type === 'WOTC' ? (
                            <Box
                                key="unlimitedSet"
                                m={1}
                                textAlign="center"
                                sx={{ color: '#ed6c02' }}
                            >
                                <CircularProgressWithLabel
                                    value={normaliseUnlimitedSet(collectedUnlimitedSet)}
                                    size={80}
                                    color="inherit" // Orange
                                />
                                <Typography variant="body2">
                                    Unlimited
                                    <br />
                                    {collectedUnlimitedSet} / {unlimitedSet}
                                </Typography>
                            </Box>
                        ) : (
                            <Box key="mainSet" m={1} textAlign="center" sx={{ color: '#ed6c02' }}>
                                <CircularProgressWithLabel
                                    value={normaliseMainSet(collectedMainSet)}
                                    size={80}
                                    color="inherit" // Orange
                                />
                                <Typography variant="body2">
                                    Main Set
                                    <br />
                                    {collectedMainSet} / {mainSetWithSecretRares}
                                </Typography>
                            </Box>
                        )}

                        {showfourthPrintSet && (
                            <Box
                                key="fourthPrintSet"
                                m={1}
                                textAlign="center"
                                sx={{ color: '#d32f2f' }}
                            >
                                <CircularProgressWithLabel
                                    value={normaliseFourthPrint(collectedFourthPrint)}
                                    size={80}
                                    color="inherit" // Red
                                />
                                <Typography variant="body2">
                                    4-Print
                                    <br />
                                    {collectedFourthPrint} / {fourthPrintSet}
                                </Typography>
                            </Box>
                        )}

                        {showReverseSet && (
                            <Box
                                key="reverseSet"
                                m={1}
                                textAlign="center"
                                sx={{ color: '#7b1fa2' }}
                            >
                                <CircularProgressWithLabel
                                    value={normaliseReverseSet(collectedReverseHoloSet)}
                                    size={80}
                                    color="inherit" // Deep Purple
                                />
                                <Typography variant="body2">
                                    Reverse
                                    <br />
                                    {collectedReverseHoloSet} / {reverseSet}
                                </Typography>
                            </Box>
                        )}

                        {showSubSet && (
                            <Box key="subSet" m={1} textAlign="center" sx={{ color: '#0288d1' }}>
                                <CircularProgressWithLabel
                                    value={normaliseSubSet(collectedSubSet)}
                                    size={80}
                                    color="inherit" // Light Blue
                                />
                                <Typography variant="body2">
                                    Sub-Set
                                    <br />
                                    {collectedSubSet} / {subSet}
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                    <Box key="totalSet" m={1} textAlign="center" sx={{ color: '#2e7d32' }}>
                        <CircularProgressWithLabel
                            value={normaliseTotal(collectedCards)}
                            size={100}
                            color="inherit" // Green
                        />
                        <Typography variant="body1" fontWeight="bold">
                            Total Progress
                            <br />
                            {collectedCards} / {numberOfCards}
                        </Typography>
                    </Box>
                </Box>
            </Collapse>
        </Paper>
    );
};

export default DesktopProgressDisplay;
