import React from 'react';
import { Box, Typography, TextField } from '@mui/material';

interface PokemonSetManagerHeaderProps {
    totalSets: number;
    searchTerm: string;
    onSearchChange: (value: string) => void;
}

const PokemonSetManagerHeader: React.FC<PokemonSetManagerHeaderProps> = ({
    totalSets,
    searchTerm,
    onSearchChange
}) => {
    return (
        <Box sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
                Pokemon Set Manager
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Total Sets: {totalSets}
            </Typography>
            <TextField
                label="Search Sets"
                variant="outlined"
                size="small"
                fullWidth
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
                sx={{ mt: 2 }}
            />
        </Box>
    );
};

export default PokemonSetManagerHeader; 