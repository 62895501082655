import React, { useState } from 'react';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import Flag from 'react-country-flag';
import { countries } from '../../data/countryFlags';

interface CountrySelectorProps {
    onChange: (code: string) => void;
    value: string;
    sx?: any;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({ onChange, value, sx }) => {
    const [selectedCountry, setSelectedCountry] = useState<string>('');

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedCountry(event.target.value);
        onChange(event.target.value);
    };

    return (
        <Select
            value={selectedCountry}
            onChange={handleChange}
            displayEmpty
            fullWidth
            className="country-selector"
            renderValue={(value) => {
                if (!value) {
                    return 'Select a country';
                }
                const country = countries.find((c) => c.code === value);
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <Flag countryCode={country?.code || ''} svg className="country-flag" />
                        <span className="country-name">{country?.name}</span>
                    </div>
                );
            }}
        >
            {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                    <Flag countryCode={country.code} svg className="country-flag" />
                    <span className="country-name">{country.name}</span>
                </MenuItem>
            ))}
        </Select>
    );
};

export default CountrySelector;
