import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/login.css';
import ReactGA from 'react-ga4';
import { Container, Grid, Paper, Avatar, Button } from '@mui/material';
import Typography from '@mui/joy/Typography';
import { styled } from '@mui/system';
import Flag from 'react-country-flag';
import assets from '../../assets';
import { Sheet } from '@mui/joy';
import '../../css/cardTemplate.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useFollowFunctions } from '../../hooks/useFollowFunctions';
import { useFriendFunctions } from '../../hooks/useFriendFunctions';
import { useUserFunctions } from '../../hooks/useUserFunctions';
import EditProfilePopup from '../../components/userProfile/EditProfilePopup';
import { setFavoritePokemonImage } from '../../redux/slices/authSlice';
import { useAuth } from '../../context/AuthContext';
import config from '../../routes/config';
import CollectionStatsPanel from '../../components/userProfile/CollectionStatsPanel';
import { useCollectionStats } from '../../hooks/useCollectionStats';
import { Skeleton } from '@mui/material';
import { useCollectionSummary } from '../../hooks/useCollectionSummary';
import CollectionSummaryPanel from '../../components/userProfile/CollectionSummaryPanel';
import ActivityFeedPanel from '../../components/userProfile/ActivityFeedPanel';

const StyledContainer = styled(Container)({
    padding: '20px',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: '20px',
    height: '100%',
}));

const ProfileBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    height: '100%',
}));

const StyledAvatar = styled(Avatar)({
    width: 100,
    height: 100,
    margin: '0 auto 16px auto',
});

const StatBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    height: '100%',
});

const StatValue = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '8px',
});

const StatLabel = styled(Typography)({
    fontSize: '0.875rem',
    color: 'text.secondary',
    textAlign: 'center',
});

const UserProfile: React.FC = () => {
    ReactGA.initialize('G-677WMVXWLS');

    const { userName } = useParams<{ userName?: string }>();
    const dispatch = useDispatch();
    const authState = useSelector((state: any) => state.auth);
    const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
    const [joinDate, setJoinDate] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('CA');

    const [userExists, setUserExists] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);

    const { isFollowing, followerCount, followLoading, handleFollowUser, handleUnfollowUser } =
        useFollowFunctions(userName);

    const {
        friendStatus,
        friendCount,
        isRequester,
        friendLoading,
        handleFriendAction,
        handleFriendResponse,
    } = useFriendFunctions(userName);

    const { getUserDetails, checkUsername, loading: userLoading } = useUserFunctions();
    const [userDetails, setUserDetails] = useState<any>(null);

    const [isCurrentUser, setIsCurrentUser] = useState(false);

    const { authParams } = useAuth();

    const { stats, loading: statsLoading, error: statsError } = useCollectionStats(userName || '');

    const { summary, loading: summaryLoading, error: summaryError } = useCollectionSummary(userName || '');

    const checkIsCurrentUser = useCallback(() => {
        const currentUserName = authState.userName || localStorage.getItem('userName');
        if (currentUserName && userName) {
            setIsCurrentUser(currentUserName === userName);
        }
    }, [authState.userName, userName]);

    useEffect(() => {
        checkIsCurrentUser();
    }, [checkIsCurrentUser]);

    const fetchUserDetails = useCallback(async () => {
        if (userName) {
            setLoading(true);
            try {
                const details = await getUserDetails(userName);
                if (details) {
                    setUserDetails(details);
                    setCountryCode(details.countryCode || 'CA');
                    checkIsCurrentUser();

                    if (isCurrentUser && details.favoritePokemonImage) {
                        dispatch(setFavoritePokemonImage(details.favoritePokemonImage));
                    }
                } else {
                    toast.error('Failed to fetch user details. Please try refreshing the page.');
                }
            } catch (error) {
                toast.error('An error occurred while fetching user details.');
            } finally {
                setLoading(false);
                setDataLoaded(true);
            }
        }
    }, [userName, getUserDetails, checkIsCurrentUser, isCurrentUser, dispatch]);

    useEffect(() => {
        if (!userName) {
            return;
        }

        const checkUsernameExists = async () => {
            const exists = await checkUsername(userName);
            setUserExists(exists);
            if (exists) {
                fetchUserDetails();
            } else {
                setLoading(false);
            }
        };

        checkUsernameExists();
    }, [userName, fetchUserDetails]);

    useEffect(() => {
        if (dataLoaded && !userDetails) {
            toast.error('User details are not available. Please try refreshing the page.');
        }
    }, [dataLoaded, userDetails]);

    const handleEditProfile = () => {
        if (userDetails) {
            setIsEditProfileOpen(true);
        } else {
            toast.error('User details are not available. Please try refreshing the page.');
        }
    };

    const handleCloseEditProfile = () => {
        setIsEditProfileOpen(false);
    };

    const handleSaveProfile = async (pokemonId: number, newCountryCode: string) => {
        setIsEditProfileOpen(false);
        await fetchUserDetails();
    };

    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if (!userName) return;

        dispatch({ type: 'SET_PAGE', payload: 'UserProfile' });
    }, [userName, dispatch]);

    useEffect(() => {
        if (!userName) return;

        const fetchUserJoinDate = async () => {
            try {
                const queryString = new URLSearchParams({ userName }).toString();
                const response = await fetch(`${config.API_URL}/getUserJoinDate?${queryString}`, {
                    credentials: 'include' as RequestCredentials,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    method: 'GET'
                });
                const result = await response.json();

                if (result.success) {
                    const date = new Date(result.joinDate);
                    setJoinDate(
                        date.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        })
                    );
                } else {
                    toast.error('Failed to fetch user join date');
                }
            } catch (error) {
                toast.error('An error occurred while fetching user join date');
                // console.error('Error fetching user join date:', error);
            }
        };

        fetchUserJoinDate();
    }, [userName]);

    if (loading || userLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress size={40} />
            </Box>
        );
    }

    if (!userExists) {
        return (
            <Typography level="h4" component="div" textAlign="center">
                User does not exist
            </Typography>
        );
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <StyledContainer>
                <Grid container spacing={3}>
                    {/* Profile Info */}
                    <Grid item xs={12} md={4}>
                        <StyledPaper>
                            <ProfileBox>
                                <StyledAvatar
                                    src={userDetails?.favoritePokemonImage || assets.site.images.backupImage}
                                    alt={userName}
                                />
                                <Typography level="h4" sx={{ mb: 2 }}>
                                    {userName}
                                </Typography>

                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={6}>
                                        <StatBox>
                                            <StatLabel>Join Date</StatLabel>
                                            <StatValue>
                                                {loading ? (
                                                    <Skeleton width={60} height={24} />
                                                ) : (
                                                    new Date(userDetails?.joinDate).toLocaleDateString()
                                                )}
                                            </StatValue>
                                        </StatBox>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StatBox>
                                            <StatLabel>Country</StatLabel>
                                            <Flag
                                                countryCode={countryCode}
                                                svg
                                                style={{ width: '24px', height: '24px', marginTop: '8px' }}
                                            />
                                        </StatBox>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <StatBox>
                                            <StatLabel>Friends</StatLabel>
                                            <StatValue>{loading ? <Skeleton width={40} /> : friendCount}</StatValue>
                                            {authState.authenticated && authState.userName !== userName && (
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        if (friendStatus === 'pending' && !isRequester) {
                                                            handleFriendResponse('accept');
                                                        } else {
                                                            handleFriendAction();
                                                        }
                                                    }}
                                                    color={friendStatus === 'friends' ? 'success' : 'primary'}
                                                    disabled={friendLoading || (friendStatus === 'pending' && isRequester)}
                                                    sx={{ mt: 1 }}
                                                >
                                                    {friendLoading ? (
                                                        <CircularProgress size={24} />
                                                    ) : friendStatus === 'friends' ? (
                                                        'Remove Friend'
                                                    ) : friendStatus === 'pending' ? (
                                                        isRequester ? (
                                                            'Pending'
                                                        ) : (
                                                            'Accept Request'
                                                        )
                                                    ) : (
                                                        'Add Friend'
                                                    )}
                                                </Button>
                                            )}
                                        </StatBox>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StatBox>
                                            <StatLabel>Followers</StatLabel>
                                            <StatValue>{loading ? <Skeleton width={40} /> : followerCount}</StatValue>
                                            {authState.authenticated && authState.userName !== userName && (
                                                <Button
                                                    variant="contained"
                                                    onClick={isFollowing ? handleUnfollowUser : handleFollowUser}
                                                    disabled={followLoading}
                                                    sx={{ mt: 1 }}
                                                >
                                                    {followLoading ? (
                                                        <CircularProgress size={24} />
                                                    ) : isFollowing ? (
                                                        'Unfollow'
                                                    ) : (
                                                        'Follow'
                                                    )}
                                                </Button>
                                            )}
                                        </StatBox>
                                    </Grid>
                                </Grid>

                                {isCurrentUser && userDetails && (
                                    <Button 
                                        variant="contained" 
                                        onClick={handleEditProfile}
                                        sx={{ mt: 2 }}
                                    >
                                        Edit Profile
                                    </Button>
                                )}
                            </ProfileBox>
                        </StyledPaper>
                    </Grid>

                    {/* Collection Stats */}
                    <Grid item xs={12} md={8}>
                        <CollectionStatsPanel 
                            userName={userName || ''} 
                            loading={loading || statsLoading}
                            stats={stats}
                            error={statsError}
                        />
                    </Grid>

                    {/* Collection Summary */}
                    <Grid item xs={12}>
                        <CollectionSummaryPanel
                            userName={userName || ''}
                            loading={loading || summaryLoading}
                            summary={summary}
                            error={summaryError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ActivityFeedPanel userName={userName || ''} />
                    </Grid>

                </Grid>
            </StyledContainer>
            {userDetails && (
                <EditProfilePopup
                    open={isEditProfileOpen}
                    onClose={handleCloseEditProfile}
                    onSave={handleSaveProfile}
                    currentFavoritePokemon={userDetails?.favoritePokemonId || null}
                    currentFavoritePokemonImage={userDetails?.favoritePokemonImage || null}
                    currentCountryCode={countryCode}
                />
            )}
        </>
    );
};

export default UserProfile;
